import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum ReferralPayoutMode {
  Yearly = 'yearly',
  Instant = 'instant',
  None = 'none',
}

export const referralPayoutModeValues: ValueLabelLookup[] = [
  { label: nameof(ReferralPayoutMode.Yearly), value: ReferralPayoutMode.Yearly },
  { label: nameof(ReferralPayoutMode.Instant), value: ReferralPayoutMode.Instant },
  { label: nameof(ReferralPayoutMode.None), value: ReferralPayoutMode.None },
];

export const referralPayoutModeLookup = keyBy(referralPayoutModeValues, 'value');
