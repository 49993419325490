import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ContextReduxState } from 'context/contextTypes';
import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import { Link } from 'react-router-dom';
import classes from './dashboardPage.module.scss';
import { dashboardActions } from './dashboardPageSlice';
import { DashboardPageReduxState } from './dashboardPageTypes';

const DashboardPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: DashboardPageReduxState) => s.dashboard.pageLoading,
  );
  const name = useSelector(
    (s: ContextReduxState) => s.context.user.user.name,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(dashboardActions.init());
  }, []);

  return (
    <ContentContainer>
      <PageTitle>{`Hello ${name}!`}</PageTitle>
      <div className="row">
        <div className="col-12">
          <Card title="Main Menu">
            <div className={classes['quick-links-container']}>
              <Link className="btn btn-outline-dark my-1" to="/member/investment/create">+ Add Investment</Link>
              <Link className="btn btn-outline-dark my-1" to="/member/investment/list">Investment List</Link>
              <div className="my-3" />
              <Link className="btn btn-outline-dark my-1" to="/member/spot-trade/create">+ Add Spot Trade</Link>
              <Link className="btn btn-outline-dark my-1" to="/member/spot-trade/list">Trade List</Link>
              <Link className="btn btn-outline-dark my-1" to="/member/spot-trade/spot-quantity-by-maturity/">Spot Quantity by Maturity Date</Link>
              <div className="my-3" />
              <Link className="btn btn-outline-dark my-1" to="/member/future/detail">Future Account</Link>
              <Link className="btn btn-outline-dark my-1" to="/member/future/settlement">+ Future Account Settlement</Link>
              <div className="my-3" />
              <h3>Reports</h3>
              <Link className="btn btn-outline-dark my-1" to="/member/report/spot-account">Spot Account Summary</Link>
              <Link className="btn btn-outline-dark my-1" to="/member/report/close-report">Investment Close Report</Link>
            </div>
          </Card>
        </div>
      </div>
    </ContentContainer>
  );
};

export default DashboardPage;
