interface AppConfig {
  apiUrl: string,
  appEnv: 'development'|'staging'|'prod',
  appVersion: number,
}

let appConfigMutatable: AppConfig = {
  apiUrl: '',
  appEnv: 'development',
  appVersion: 1,
};

declare let config: AppConfig;

// In development mode, we will fetch the configuration from process.env.
// In production mode, we will fetch the configuration injected from the index.html.
if (process.env.NODE_ENV === 'production') {
  appConfigMutatable = config;
} else {
  appConfigMutatable = {
    apiUrl: process.env.API_URL || '',
    // External environment.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appEnv: <any>process.env.APP_ENV || 'development',
    appVersion: Number(process.env.APP_VERSION) || 1,
  };
}

const appConfig = appConfigMutatable;

export function isUseV2Calculation(): boolean {
  return appConfig.appVersion >= 2;
}

export default appConfig;
