import * as React from 'react';
import Select, { Option } from 'rc-select';
import keyBy from 'lodash/keyBy';
import clsx from 'clsx';

export interface FormDropDownListItem {
  label: string,
  value: string|number|boolean,
}

interface FormDropDownListProps {
  id?: string,
  placeholder?: string,
  disabled?: boolean,
  values: FormDropDownListItem[]
  value?: string|number|boolean|null,
  className?: string,
  onChange: (value: string|number|boolean|null) => void,
  onBlur?: () => void,
}

const FormDropDownList: React.FC<FormDropDownListProps> = ({
  id,
  placeholder,
  disabled,
  values,
  value,
  className,
  onChange,
  onBlur,
}) => {
  // rc-select has a weird decision to directly show value to the user
  // instead of label. Therefore we have no choice but to base everything
  // to label instead of value.
  // Should have no problem merging both keys to single lookup as they rarely conflicts.
  const valuesLookup = React.useMemo(() => values && ({
    ...keyBy(values, 'label'),
    ...keyBy(values, 'value'),
  }), [values]);

  const onSelected = React.useCallback((val) => {
    if (valuesLookup[val]) {
      onChange(valuesLookup[val].value);
    } else {
      onChange(null);
    }
  }, [onChange, valuesLookup]);

  const displayValue = value
    && valuesLookup[value.toString()]
    && valuesLookup[value.toString()].label;

  const classNames = clsx(
    'form-control',
    className,
  );

  return (
    <Select
      className={classNames}
      id={id}
      showSearch
      showArrow
      placeholder={placeholder == null ? 'Please select' : placeholder}
      onSelect={onSelected}
      onDeselect={onSelected}
      onBlur={onBlur}
      value={displayValue}
      disabled={disabled}
      optionFilterProp="children"
    >
      {values.map((v) => (
        <Option key={v.value.toString()} value={v.label}>{v.label}</Option>
      ))}
    </Select>
  );
};

export default FormDropDownList;
