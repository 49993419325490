import { ContextReduxState } from 'context/contextTypes';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Redirect user to specific page based on if user already logged in.
const BasePage: React.FC = () => {
  const isLoggedIn = useSelector((s: ContextReduxState) => s.context.user.isLoggedIn);
  const isInitCompleted = useSelector((s: ContextReduxState) => s.context.app.isInitCompleted);

  if (isInitCompleted && isLoggedIn) {
    return <Redirect to="/member/dashboard" />;
  } if (isInitCompleted && !isLoggedIn) {
    return <Redirect to="/auth/sign-in" />;
  }

  return null;
};

export default BasePage;
