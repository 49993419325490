import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory, useParams } from 'react-router';
import Card from 'common/ui/Card';
import LabelValueRow from 'common/ui/LabelValueRow';
import { formatCurrency } from 'common/util/currency';
import {
  FormikDatePicker,
  FormikDropDownList, FormikForm, FormikNumberField,
  FormikTextField,
} from 'common/form';
import { Alert, AlertType, LoadingAlert } from 'common/ui/Alert';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button from 'common/ui/Button';
import { formatDate } from 'common/util/date';
import { isUseV2Calculation } from 'common/setup/config';
import validateCloseInvestmentForm from './closeInvestmentPageValidation';
import { closeInvestmentActions } from './closeInvestmentPageSlice';
import { CloseInvestmentPageReduxState } from './closeInvestmentPageTypes';
import { InvestmentCloseType, investmentCloseTypeValues } from './investmentCloseType';
import { ExchangeNetwork, exchangeNetworkValues } from '../shared/exchangeNetwork';
import { InvestmentStatus } from '../shared/investmentStatus';
import { referralDjModeLookup } from '../shared/referralDjMode';
import setReferralFee from '../shared/setReferralFee';
import getProfiltSharingRatioText from '../shared/getProfiltSharingRatioText';

const CloseInvestmentPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CloseInvestmentPageReduxState) => s.closeInvestment.pageLoading,
  );
  const formLoading = useSelector(
    (s: CloseInvestmentPageReduxState) => s.closeInvestment.formLoading,
  );
  const detail = useSelector(
    (s: CloseInvestmentPageReduxState) => s.closeInvestment.detail,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const idNumber = parseInt(id, 10);
    dispatch(closeInvestmentActions.init(idNumber));
  }, []);

  const maturityDatePlusYear = isUseV2Calculation() ? 3 : 1;

  return (
    <ContentContainer>
      <PageTitle onBack={() => history.goBack()}>
        Close Investment
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card
          title="Summary"
        >
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Form No.">
                {detail.formNo}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Customer">
                {detail.customerName}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Amount">
                {`${formatCurrency(detail.amount, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Maturity Date">
                {formatDate(detail.maturityDate)}
              </LabelValueRow>
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card
          title="Settlement Preview & Payables"
        >
          <div className="row">
            <div className="col-12">
              <LabelValueRow label="Payout to Date">
                {`${formatCurrency(detail.totalPartialPayout, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Final Balance">
                {`${formatCurrency(detail.finalBalance, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Total Profit">
                {`${formatCurrency(detail.totalProfit, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Investor Profit">
                {`${formatCurrency(detail.investorProfit, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Company Profit">
                {`${formatCurrency(detail.companyProfit, 'two')} USDT`}
              </LabelValueRow>
            </div>

            <div className="col-12 my-2">
              <strong>{`${formatCurrency(detail.investorProfit + detail.amount, 'two')} USDT `}</strong>
              payable to investor.
            </div>
            {detail.referralProfit ? (
              <div className="col-12 my-2">
                <strong>{`${formatCurrency(detail.referralProfit, 'two')} USDT `}</strong>
                {`payable to referral ${detail.referralName}.`}
              </div>
            ) : null}
            {detail.djProfit ? (
              <div className="col-12 my-2">
                <strong>{`${formatCurrency(detail.djProfit, 'two')} USDT `}</strong>
                payable to DJ.
              </div>
            ) : null}
          </div>
        </Card>
        <div className="my-3" />
        <Card
          title="Investor Profit Breakdown"
        >
          <table className="table table-hover">
            <tbody>
              <tr>
                <td>Final Spot Value</td>
                <td>{`${formatCurrency(detail.investorBreakdown.spotValue, 'two')} USDT`}</td>
              </tr>
              <tr>
                <td>Final Spot ROI</td>
                <td>{`${formatCurrency(detail.investorBreakdown.spotRoi - 100, 'two')}%`}</td>
              </tr>
              <tr>
                <td>Final Future Value</td>
                <td>{`${formatCurrency(detail.investorBreakdown.futureValue, 'two')} USDT`}</td>
              </tr>
              <tr>
                <td>---</td>
                <td />
              </tr>
              <tr>
                <td>Total Profit</td>
                <td>{`${formatCurrency(detail.totalProfit, 'two')} USDT`}</td>
              </tr>
              <tr>
                <td>{`Sharing Profit ${getProfiltSharingRatioText('investor')}`}</td>
                <td>{`${formatCurrency(detail.investorBreakdown.fairProfit, 'two')} USDT`}</td>
              </tr>
              {detail.bonusPayoutRate > 0 && (
                <tr>
                  <td>{`Bonus Payout ${detail.bonusPayoutRate}%`}</td>
                  <td>{`${formatCurrency(detail.investorBreakdown.bonusPayout, 'two')} USDT`}</td>
                </tr>
              )}
              <tr>
                <td>Total Profit</td>
                <td><strong>{`${formatCurrency(detail.investorProfit, 'two')} USDT`}</strong></td>
              </tr>
            </tbody>
          </table>
        </Card>
        <div className="my-3" />
        <Card
          title="Company Profit Breakdown"
        >
          <table className="table table-hover">
            <tbody>
              <tr>
                <td>Total Profit</td>
                <td>{`${formatCurrency(detail.totalProfit, 'two')} USDT`}</td>
              </tr>
              <tr>
                <td>{`Sharing Profit ${getProfiltSharingRatioText('company')}`}</td>
                <td>{`${formatCurrency(detail.companyBreakdown.fairProfit, 'two')} USDT`}</td>
              </tr>
              {detail.bonusPayoutRate > 0 && (
                <tr>
                  <td>{`Bonus Payout ${detail.bonusPayoutRate}%`}</td>
                  <td>{`-${formatCurrency(detail.companyBreakdown.bonusPayout, 'two')} USDT`}</td>
                </tr>
              )}
              {detail.companyBreakdown.referralProfit > 0 && (
                <tr>
                  <td>Referral Payout</td>
                  <td>{`-${formatCurrency(detail.companyBreakdown.referralProfit, 'two')} USDT`}</td>
                </tr>
              )}
              {detail.companyBreakdown.djProfit > 0 && (
                <tr>
                  <td>Referral (DJ) Payout</td>
                  <td>{`-${formatCurrency(detail.companyBreakdown.djProfit, 'two')} USDT`}</td>
                </tr>
              )}
              <tr>
                <td>Total Profit / Loss</td>
                <td><strong>{`${formatCurrency(detail.companyProfit, 'two')} USDT`}</strong></td>
              </tr>
            </tbody>
          </table>
        </Card>
        <div className="my-3" />
        <FormikForm
          initialValues={{
            id: detail.id,
            investorPayable: detail.investorProfit + detail.amount,
            type: InvestmentCloseType.Withdraw,
            reinvestAmount: 0,
            exchangeNetwork: null,
            walletAddress: '',
            transactionHash: '',
            effectiveDate: detail.maturityDate,
            maturityDate: detail.maturityDate.plus({ year: maturityDatePlusYear }),
            referralDjFee: 0,
            referralExchangeNetwork: ExchangeNetwork.ERC20,
            referralFee: 0,
            referralTransactionDateTime: null,
            referralTransactionHash: '',
            referralWalletAddress: '',
          }}
          validate={validateCloseInvestmentForm}
          onSubmit={(form) => {
            dispatch(closeInvestmentActions.submit(form));
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Card title="Closing Form">
                <LoadingAlert loading={formLoading} />
                <div className="row">
                  <div className="col-12">
                    <FormikDatePicker
                      name={nameof(values.effectiveDate)}
                      label="Effective Date"
                      onChange={(newDate) => {
                        if (newDate) {
                          setFieldValue(
                            nameof(values.maturityDate),
                            newDate.plus({ year: maturityDatePlusYear }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <FormikDropDownList
                      name={nameof(values.type)}
                      label="Investor Will"
                      values={investmentCloseTypeValues}
                      onChange={(value) => {
                        if (value === InvestmentCloseType.Withdraw) {
                          setFieldValue(nameof(values.reinvestAmount), 0);
                          setFieldValue(nameof(values.walletAddress), '');
                          setFieldValue(nameof(values.transactionHash), '');
                          setFieldValue(nameof(values.exchangeNetwork), null);
                        }
                      }}
                    />
                  </div>
                  {values.type === InvestmentCloseType.Reinvest && (
                    <div className="col-12">
                      <FormikNumberField
                        name={nameof(values.reinvestAmount)}
                        label="Reinvest Amount"
                        decimal={2}
                        onBlur={(value) => {
                          if (value && value <= values.investorPayable) {
                            setFieldValue(nameof(values.walletAddress), '');
                            setFieldValue(nameof(values.transactionHash), '');
                            setFieldValue(nameof(values.exchangeNetwork), null);
                          } if (value) {
                            setReferralFee(values, value, detail.referralDjMode, setFieldValue);
                          }
                        }}
                      />
                    </div>
                  )}
                  {values.type === InvestmentCloseType.Reinvest && values.reinvestAmount ? (
                    <div className="col-12">
                      <Alert type={AlertType.info}>
                        {values.investorPayable >= values.reinvestAmount
                          ? `Surplus of ${formatCurrency(values.investorPayable - values.reinvestAmount, 'two')} USDT is payable back to investor.`
                          : `Investor need to top up ${formatCurrency(values.reinvestAmount - values.investorPayable, 'two')} USDT for new investment.`}
                      </Alert>
                    </div>
                  ) : null}
                  {values.type === InvestmentCloseType.Reinvest && (
                    <div className="col-12">
                      <FormikDatePicker
                        name={nameof(values.maturityDate)}
                        label="New Maturity Date"
                      />
                    </div>
                  )}
                  {values.reinvestAmount > values.investorPayable && (
                    <>
                      <div className="col-12 col-md-6">
                        <FormikDropDownList
                          id={nameof(values.exchangeNetwork)}
                          name={nameof(values.exchangeNetwork)}
                          label="Exchange Network"
                          values={exchangeNetworkValues}
                        />
                      </div>
                      <div className="col-md-6" />
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.walletAddress)}
                          name={nameof(values.walletAddress)}
                          label="Wallet Address"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.transactionHash)}
                          name={nameof(values.transactionHash)}
                          label="Transaction Hash"
                        />
                      </div>
                    </>
                  )}
                  {values.type === InvestmentCloseType.Reinvest && (
                    <>
                      <div className="col-12">
                        <h3>Referral</h3>
                      </div>
                      <div className="col-12">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="m-0">Referral DJ Mode</label>
                        <div className="mb-2">
                          {detail.referralDjMode ? referralDjModeLookup[detail.referralDjMode].label : '-'}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikNumberField
                          id={nameof(values.referralFee)}
                          name={nameof(values.referralFee)}
                          label="Referral Fee"
                          inputPreLabel="USDT"
                          decimal={2}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikNumberField
                          id={nameof(values.referralDjFee)}
                          name={nameof(values.referralDjFee)}
                          label="DJ Referral Fee"
                          inputPreLabel="USDT"
                          decimal={2}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikDatePicker
                          id={nameof(values.referralTransactionDateTime)}
                          name={nameof(values.referralTransactionDateTime)}
                          label="Transaction Date & Time"
                          time
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikDropDownList
                          id={nameof(values.referralExchangeNetwork)}
                          name={nameof(values.referralExchangeNetwork)}
                          label="Exchange Network"
                          values={exchangeNetworkValues}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.referralWalletAddress)}
                          name={nameof(values.referralWalletAddress)}
                          label="Wallet Address"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.referralTransactionHash)}
                          name={nameof(values.referralTransactionHash)}
                          label="Transaction Hash"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>
              {!detail.isClean && (
                <div className="my-3">
                  <Alert type={AlertType.error}>
                    Unable to close investment.
                    {' '}
                    The investment still holding some spot coins.
                    {' '}
                    Please sell all the coins before proceeding.
                  </Alert>
                </div>
              )}
              {detail.status !== InvestmentStatus.PendingSettlement && (
                <div className="my-3">
                  <Alert type={AlertType.error}>
                    Unable to close investment. The status must be set to Pending Settlement.
                  </Alert>
                </div>
              )}
              <FooterActionContainer>
                <Button
                  type="submit"
                  label="Close Investment"
                  isLoading={
                    !detail.isClean || detail.status !== InvestmentStatus.PendingSettlement
                    || formLoading.isLoading
                  }
                />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CloseInvestmentPage;
