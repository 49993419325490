export interface IHasId {
  id: string|number;
}

export interface RemoteList<T extends IHasId> {
  list: T[];
  // eslint-disable-next-line camelcase
  meta: { total_page: number, current_page: number };
}

export interface List<T> {
  info: ListInfo;
  data: T[];
  // An object that maps the object ID to the array index it is residing.
  idIndexMap: Record<number, number>;
}

export interface ListInfo {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  nextCursor?: number;
  prevCursor?: number;
  currentCursor?: number;
  currentPointer?: PageScrollDirection;
}

export const defaultList: List<never> = {
  info: {
    hasPreviousPage: false,
    hasNextPage: false,
  },
  data: [],
  idIndexMap: {},
};

export enum PageScrollDirection {
  Next, Previous, First, Stay,
}

export const getListRequestQueryString = (
  currentInfo: ListInfo,
  direction: PageScrollDirection,
): string => {
  const currentPage = currentInfo.currentCursor || 0;
  let nextPage = currentPage;
  if (direction === PageScrollDirection.Next) {
    nextPage = currentPage + 1;
  } if (direction === PageScrollDirection.Previous) {
    nextPage = currentPage - 1;
  } if (direction === PageScrollDirection.First) {
    nextPage = 1;
  }

  return `page_size=${DEFAULT_PAGE_SIZE}&page=${nextPage}`;
};

export const mapRemoteList = <
  TRemote extends IHasId,
  TAfter extends IHasId,
  TContext = unknown
>(
    remoteList: RemoteList<TRemote>,
    mapFunc: (remoteList: TRemote[], context?: unknown) => TAfter[],
    context?: TContext,
  ): List<TAfter> => {
  const info: ListInfo = {
    hasNextPage: remoteList.meta.total_page > remoteList.meta.current_page,
    hasPreviousPage: remoteList.meta.current_page > 1,
    nextCursor: remoteList.meta.current_page + 1,
    prevCursor: remoteList.meta.current_page - 1,
    currentCursor: remoteList.meta.current_page,
    // Not going to use this, so it is fine to hardcode.
    currentPointer: PageScrollDirection.Next,
  };

  const data = mapFunc(remoteList.list, context);

  const idIndexMap = data.reduce<Record<string, number>>((prev, cur, index) => {
    // eslint-disable-next-line no-param-reassign
    prev[cur.id] = index;
    return prev;
  }, {});

  return {
    info,
    data,
    idIndexMap,
  };
};

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_SEARCH_DEBOUNCE = 500;
