import * as React from 'react';
import { LoadingData } from 'common/util/loading';
import Alert, { AlertType } from './Alert';

export interface LoadingAlertProps {
  loading: LoadingData,
}

const LoadingAlert: React.FC<LoadingAlertProps> = ({ loading }) => {
  let type: AlertType | undefined;
  if (loading.isSuccess) {
    type = AlertType.success;
  } if (loading.isError) {
    type = AlertType.error;
  }

  return (type && loading.message) ? (
    <Alert type={type}>
      {loading.message}
    </Alert>
  ) : null;
};

export default LoadingAlert;
