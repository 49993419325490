import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { customerPortalActions } from './customerPortalPageSlice';
import { CustomerPortalPageReduxState } from './customerPortalPageTypes';
import CustomerLogin from './CustomerLogin';
import CustomerInvestment from './CustomerInvestment';

const CustomerPortalPage: React.FC = () => {
  const dispatch = useDispatch();

  const loginLoading = useSelector(
    (s: CustomerPortalPageReduxState) => s.customerPortal.loginLoading,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(customerPortalActions.init());
  }, []);

  return (
    loginLoading.isSuccess ? (
      <CustomerInvestment />
    ) : (
      <CustomerLogin />
    )
  );
};

export default CustomerPortalPage;
