import { formatCurrency } from 'common/util/currency';
import * as React from 'react';
import CardSummaryBlock from './CardSummaryBlock';
import classes from './cardSummaryBlock.module.scss';

interface CardSummaryCurrencyBlockProps {
  value: number,
  title: string,
}

const CardSummaryCurrencyBlock: React.FC<CardSummaryCurrencyBlockProps> = ({ title, value }) => (
  <CardSummaryBlock title={title}>
    {formatCurrency(value, 'two')}
    &nbsp;
    <span className={classes['currency-end']}>USDT</span>
  </CardSummaryBlock>
);

export default CardSummaryCurrencyBlock;
