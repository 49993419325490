import { IconProps } from '@primer/octicons-react';
import clsx from 'clsx';
import * as React from 'react';
import classes from './iconButton.module.scss';

interface IconButtonProps {
  id?: string,
  'data-toggle'?: string,
  'aria-haspopup'?: React.AriaAttributes['aria-haspopup'],
  'aria-expanded'?: React.AriaAttributes['aria-expanded'],
  icon: React.ElementType<IconProps>,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  size?: 'small' | 'medium' | 'large',
}

const Button: React.FC<IconButtonProps> = ({
  id,
  icon: Icon, onClick,
  size,
  'data-toggle': dataToggle,
  'aria-haspopup': ariaHasPopup,
  'aria-expanded': ariaExpanded,
}) => (
  <button
    id={id}
    type="button"
    className={clsx('btn-reset', classes['btn-icon'])}
    onClick={onClick}
    data-toggle={dataToggle}
    aria-haspopup={ariaHasPopup}
    aria-expanded={ariaExpanded}
  >
    <Icon size={size || 'small'} />
  </button>
);

export default Button;
