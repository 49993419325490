import { formatCurrency } from 'common/util/currency';
import * as React from 'react';
import CardSummaryBlock from './CardSummaryBlock';
import classes from './cardSummaryBlock.module.scss';

interface CardSummaryPercentBlockProps {
  value: number,
  title: string,
}

const CardSummaryPercentBlock: React.FC<CardSummaryPercentBlockProps> = ({ title, value }) => (
  <CardSummaryBlock title={title}>
    {formatCurrency(value, 'four')}
    &nbsp;
    <span className={classes['currency-end']}>%</span>
  </CardSummaryBlock>
);

export default CardSummaryPercentBlock;
