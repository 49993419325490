import { ISagaModule } from 'redux-dynamic-modules-saga';
import dashboardSaga from './dashboardPageSaga';
import { dashboardReducer } from './dashboardPageSlice';
import { DashboardPageReduxState } from './dashboardPageTypes';

export default (): ISagaModule<DashboardPageReduxState> => ({
  id: 'dashboard',
  reducerMap: {
    dashboard: dashboardReducer,
  },
  sagas: [dashboardSaga],
  retained: true,
});
