/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ContextReduxState } from 'context/contextTypes';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const isLoggedIn = useSelector((s: ContextReduxState) => s.context.user.isLoggedIn);
  const isInitCompleted = useSelector((s: ContextReduxState) => s.context.app.isInitCompleted);

  if (!isInitCompleted) {
    return null;
  }

  if (!isLoggedIn) {
    return <Redirect to="/auth/sign-in?from=unauthorized" />;
  }

  return (
    <Route {...props} />
  );
};

export default ProtectedRoute;
