import clsx from 'clsx';
import * as React from 'react';

interface PaginationProps {
  hasPrevPage: boolean,
  onPrevPageClick: () => void,
  hasNextPage: boolean,
  onNextPageClick: () => void,
}

const Pagination: React.FC<PaginationProps> = ({
  hasPrevPage, onPrevPageClick, hasNextPage, onNextPageClick,
}) => {
  const onNextClick = React.useCallback((e) => {
    e.preventDefault();
    onNextPageClick();
  }, [onNextPageClick]);

  const onPrevClick = React.useCallback((e) => {
    e.preventDefault();
    onPrevPageClick();
  }, [onPrevPageClick]);

  return (
    <nav aria-label="Page navigation" className="d-flex justify-content-center w-100">
      <ul className="pagination">
        <li className={clsx('page-item', !hasPrevPage && 'disabled')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="page-link"
            href="#"
            onClick={onPrevClick}
            aria-disabled={!hasPrevPage}
            tabIndex={!hasPrevPage ? -1 : undefined}
          >
            &lt; Prev
          </a>
        </li>
        <li className={clsx('page-item', !hasNextPage && 'disabled')}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="page-link"
            href="#"
            onClick={onNextClick}
            aria-disabled={!hasNextPage}
            tabIndex={!hasNextPage ? -1 : undefined}
          >
            Next &gt;
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
