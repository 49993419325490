import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from 'redux';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api';
import { getTradePrices } from 'context/trade/tradeSaga';
import { customerPortalActions } from './customerPortalPageSlice';
import { mapCustomerPortalInvestmentFromApi } from './customerPortalPageMapper';
import { CustomerPortalInvestmentRemote } from './customerPortalPageTypes';

function* onInit(): Generator {
  yield put(customerPortalActions.setInitialState());
}

function* onSubmit(action: Action): Generator {
  if (!customerPortalActions.submitLoginForm.match(action)) { return; }

  const [loadingStart, loadingError, loadingSuccess] = bindLoadingActions(
    customerPortalActions.setLoginLoading,
  );

  yield put(loadingStart());

  try {
    const resp = yield* call(ApiService.post<CustomerPortalInvestmentRemote>(), '/v1/public/investments/portal', {
      form_no: action.payload.formNo,
      password: action.payload.password,
    });

    // Collect trade ids
    const tradeSymbols = [...new Set(resp.spot_buys.map((t) => t.coingecko_id))];
    const prices = tradeSymbols.length > 0
      ? yield* call(getTradePrices, tradeSymbols)
      : {};
    const detail = mapCustomerPortalInvestmentFromApi(resp, prices);

    yield put(customerPortalActions.setDetail(detail));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingError(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(customerPortalActions.init.type, onInit);
  yield takeLatest(customerPortalActions.submitLoginForm.type, onSubmit);
}
