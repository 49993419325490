import { ISagaModule } from 'redux-dynamic-modules-saga';
import closeReportSaga from './closeReportPageSaga';
import { closeReportReducer } from './closeReportPageSlice';
import { CloseReportPageReduxState } from './closeReportPageTypes';

export default (): ISagaModule<CloseReportPageReduxState> => ({
  id: 'closeReport',
  reducerMap: {
    closeReport: closeReportReducer,
  },
  sagas: [closeReportSaga],
  retained: true,
});
