import { IconProps, ArrowLeftIcon } from '@primer/octicons-react';
import clsx from 'clsx';
import * as React from 'react';
import classes from './pageTitle.module.scss';

interface PageTitleProps {
  children: React.ReactNode,
  onBack?: () => void,
  buttons?: Array<PageTitleButton|false>,
}

interface PageTitleButton {
  label: string,
  icon: React.ElementType<IconProps>,
  onClick: () => void,
}

const PageTitle: React.FC<PageTitleProps> = ({ children, onBack, buttons }) => (
  <>
    {onBack && (
      <button type="button" onClick={onBack} className={`btn-reset ${classes['btn-title']} ${classes.leftfix}`}>
        <ArrowLeftIcon size="small" />
        <div className="ml-1 d-inline-block">Back</div>
      </button>
    )}
    <h1 className="m-0">{children}</h1>
    {buttons && buttons.length > 0 && (
      <div className="d-flex">
        {buttons.map((b, i) => (b ? (
          <button
            type="button"
            className={clsx('btn-reset', classes['btn-title'], i === 0 && classes.leftfix)}
            key={b.label}
            onClick={b.onClick}
          >
            <b.icon size="small" />
            <div className="ml-1 d-inline-block">{b.label}</div>
          </button>
        ) : null))}
      </div>
    )}
    <div className="pb-3" />
  </>
);

export default PageTitle;
