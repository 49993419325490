import { ISagaModule } from 'redux-dynamic-modules-saga';
import futureDetailSaga from './futureDetailPageSaga';
import { futureDetailReducer } from './futureDetailPageSlice';
import { FutureDetailPageReduxState } from './futureDetailPageTypes';

export default (): ISagaModule<FutureDetailPageReduxState> => ({
  id: 'futureDetail',
  reducerMap: {
    futureDetail: futureDetailReducer,
  },
  sagas: [futureDetailSaga],
  retained: true,
});
