import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormikForm, FormikTextField } from 'common/form';
import PageTitle from 'common/ui/PageTitle';
import ContentContainer from 'common/ui/ContentContainer';
import Card from 'common/ui/Card';
import FormikTextArea from 'common/form/FormikTextArea';
import FooterActionContainer from 'common/ui/FooterActionContainer';
// import classes from './createCustomerPage.module.scss';
import Button from 'common/ui/Button';
import { useHistory, useParams } from 'react-router';
import { LoadingAlert } from 'common/ui/Alert';
import { loadingSuccess } from 'common/util/loading';
import LoadingSwitch from 'common/ui/LoadingSwitch';
import { createCustomerActions } from './createCustomerPageSlice';
import { CreateCustomerPageParam, CreateCustomerPageReduxState } from './createCustomerPageTypes';
import validateCustomerForm from './createCustomerPageValidation';

const CreateCustomerPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<CreateCustomerPageParam>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CreateCustomerPageReduxState) => s.createCustomer.pageLoading,
  );
  const formLoading = useSelector(
    (s: CreateCustomerPageReduxState) => s.createCustomer.formLoading,
  );
  const form = useSelector(
    (s: CreateCustomerPageReduxState) => s.createCustomer.form,
  );

  // Go back after create customer is done.
  const isFirstRender = React.useRef(false);
  React.useLayoutEffect(() => {
    if (!isFirstRender.current) {
      isFirstRender.current = true;
      return;
    }

    if (formLoading.isSuccess) {
      history.goBack();
    }
  }, [formLoading]);

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(createCustomerActions.init(params));
  }, [params]);

  const onSubmit = React.useCallback((f) => {
    dispatch(createCustomerActions.submit(f));
  }, [dispatch]);

  return (
    <ContentContainer>
      <PageTitle
        onBack={() => dispatch(createCustomerActions.setFormLoading(loadingSuccess()))}
      >
        Create Customer
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <FormikForm
          initialValues={form}
          validate={validateCustomerForm}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <LoadingAlert loading={formLoading} />
              <Card title="Customer Details">
                <div className="row">
                  <div className="col-12">
                    <FormikTextField
                      label="Name"
                      name={nameof(values.name)}
                    />
                  </div>
                  <div className="col-12">
                    <FormikTextField
                      label="ID Number"
                      name={nameof(values.idNumber)}
                      placeholder="930101061111"
                    />
                  </div>
                  <div className="col-12">
                    <FormikTextField
                      label="Contact Number"
                      name={nameof(values.contactNumber)}
                      placeholder="01811112222"
                    />
                  </div>
                  <div className="col-12">
                    <FormikTextArea
                      label="Address"
                      name={nameof(values.address)}
                      rows={3}
                    />
                  </div>
                </div>
              </Card>
              <FooterActionContainer>
                <Button type="submit" label="Save" isLoading={formLoading.isLoading} />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CreateCustomerPage;
