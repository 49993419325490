import { ISagaModule } from 'redux-dynamic-modules-saga';
import signInCallbackSaga from './signInCallbackPageSaga';
import { signInCallbackReducer } from './signInCallbackPageSlice';
import { SignInCallbackPageReduxState } from './signInCallbackPageTypes';

export default (): ISagaModule<SignInCallbackPageReduxState> => ({
  id: 'signInCallback',
  reducerMap: {
    signInCallback: signInCallbackReducer,
  },
  sagas: [signInCallbackSaga],
  retained: true,
});
