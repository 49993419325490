import * as React from 'react';
import { connect, getIn } from 'formik';
import { ConnectedFormikProps } from './formikTypes';
import NumberField from './NumberField';

interface FormikNumberFieldProps {
  onBlur?: (value :number|null|undefined) => void,
  label?: string,
  inputPostLabel?: string,
  inputPreLabel?: string,

  name: string,
  id?: string,
  className?: string,
  placeholder?: string,
  disabled?: boolean,
  decimal?: number,
}

const FormikNumberField: React.FC<FormikNumberFieldProps & ConnectedFormikProps> = ({
  formik,
  name,
  id,
  placeholder,
  label,
  disabled,
  onBlur,
  inputPreLabel,
  inputPostLabel,
  decimal,
}) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const valueRaw = getIn(formik.values, name) as number|null|undefined;
  const isInvalid = error && touch;

  // In order to reset the input field, need to set the value as empty string.
  // https://github.com/s-yadav/react-number-format/issues/500#issuecomment-797342449
  const value = valueRaw === null ? '' : valueRaw;

  return (
    <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
      {label && (
      <label htmlFor={id}>
        {label}
      </label>
      )}
      <div className={`input-group ${isInvalid ? 'is-invalid' : ''}`}>
        {inputPreLabel && (
          <div className="input-group-prepend">
            <span className="input-group-text">{inputPreLabel}</span>
          </div>
        )}
        <NumberField
          decimal={decimal}
          name={name}
          className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
          id={id}
          placeholder={placeholder}
          onValueChange={(v) => formik.setFieldValue(name, v.floatValue === undefined
            ? null
            : v.floatValue)}
          onBlur={(e) => {
            formik.handleBlur(e);
            if (onBlur) {
              onBlur(valueRaw);
            }
          }}
          value={value}
          disabled={disabled}
        />
        {inputPostLabel && (
          <div className="input-group-append">
            <span className="input-group-text">{inputPostLabel}</span>
          </div>
        )}
      </div>
      {isInvalid && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default connect<FormikNumberFieldProps, unknown>(FormikNumberField);
