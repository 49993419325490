import { ISagaModule } from 'redux-dynamic-modules-saga';
import spotQuantityByMaturitySaga from './spotQuantityByMaturityPageSaga';
import { spotQuantityByMaturityReducer } from './spotQuantityByMaturityPageSlice';
import { SpotQuantityByMaturityPageReduxState } from './spotQuantityByMaturityPageTypes';

export default (): ISagaModule<SpotQuantityByMaturityPageReduxState> => ({
  id: 'spotQuantityByMaturity',
  reducerMap: {
    spotQuantityByMaturity: spotQuantityByMaturityReducer,
  },
  sagas: [spotQuantityByMaturitySaga],
  retained: true,
});
