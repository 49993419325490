import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api';
import { spotListActions } from './spotListPageSlice';
import { SpotListRecord } from './spotListPageTypes';

function* onInit(): Generator {
  yield put(spotListActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(spotListActions.setPageLoading);
  try {
    const resp = yield* call(ApiService.get<SpotListRecord[]>(), '/v1/trades');
    // eslint-disable-next-line camelcase
    const balance = yield* call(ApiService.get<{available_amount: number}>(), '/v1/spot-trades/available');
    yield put(spotListActions.setList(resp));
    yield put(spotListActions.setSpotBalance(balance.available_amount));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(spotListActions.init.type, onInit);
}
