import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import ApiService from 'common/api';
import { call } from 'typed-redux-saga';
import { futureDetailActions } from './futureDetailPageSlice';
import { FutureDetailRemote } from './futureDetailPageTypes';
import { mapFutureTransactionsFromApi } from './futureDetailPageMapper';

function* onInit(): Generator {
  yield put(futureDetailActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(futureDetailActions.setPageLoading);
  try {
    const resp = yield* call(ApiService.get<FutureDetailRemote>(), '/v1/futures');
    const mapped = mapFutureTransactionsFromApi(resp);

    yield put(futureDetailActions.setDetail(mapped));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(futureDetailActions.init.type, onInit);
}
