import * as React from 'react';

import Button from 'common/ui/Button';
import classes from './modal.module.scss';

interface ModalPromptButtonProps {
  yesLabel: string,
  noLabel: string,
  isLoading: boolean,
  onYesClick: () => void,
  onNoClick: () => void,
}

const ModalPromptButton: React.FC<ModalPromptButtonProps> = ({
  yesLabel, noLabel, isLoading, onYesClick, onNoClick,
}) => (
  <div className={classes['modal-prompt-button']}>
    <Button label={yesLabel || 'Yes'} isLoading={isLoading} onClick={onYesClick} />
    <Button label={noLabel || 'No'} onClick={onNoClick} secondary />
  </div>
);

export default ModalPromptButton;
