import * as React from 'react';
import { connect, getIn } from 'formik';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { ConnectedFormikProps } from './formikTypes';

interface FormikDatePickerProps {
  name: string,
  id?: string,
  placeholder?: string,
  label?: string,
  disabled?: boolean,
  time?: boolean,
  onChange?: (value: DateTime|null) => void,
}

const FormikDatePicker: React.FC<FormikDatePickerProps & ConnectedFormikProps> = ({
  formik,
  name,
  id,
  placeholder,
  label,
  disabled,
  time,
  onChange,
}) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const value = getIn(formik.values, name) as DateTime|null;
  const isInvalid = error && touch;

  const onChangeLocal = React.useCallback((date) => {
    const luxonDate = DateTime.fromJSDate(date);
    const saveDate = luxonDate.isValid
      ? luxonDate
      : null;
    formik.setFieldValue(name, saveDate);
    if (onChange) {
      onChange(saveDate);
    }
  }, []);

  const localDate = React.useMemo(() => value?.toJSDate(), [value]);

  return (
    <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
      { label && (
      <label htmlFor={id}>
        {label}
      </label>
      ) }
      <DatePicker
        selected={localDate}
        onChange={onChangeLocal}
        placeholderText={placeholder || 'Click here to select date'}
        showTimeSelect={time}
        disabled={disabled}
        timeFormat="h:mm aa"
        timeIntervals={1}
        timeCaption="Time"
        dateFormat={time ? 'd-M-yyyy h:mm aa' : 'd-M-yyyy'}
        name={name}
        id={id}
        wrapperClassName="d-block"
        className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
      />
      {isInvalid && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default connect<FormikDatePickerProps, unknown>(FormikDatePicker);
