import { ISagaModule } from 'redux-dynamic-modules-saga';
import spotDetailSaga from './spotDetailPageSaga';
import { spotDetailReducer } from './spotDetailPageSlice';
import { SpotDetailPageReduxState } from './spotDetailPageTypes';

export default (): ISagaModule<SpotDetailPageReduxState> => ({
  id: 'spotDetail',
  reducerMap: {
    spotDetail: spotDetailReducer,
  },
  sagas: [spotDetailSaga],
  retained: true,
});
