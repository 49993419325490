import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum SpotSellFor {
  All = 'all',
  Specific = 'specific',
}

export const spotSellForValues: ValueLabelLookup[] = [
  { label: 'All', value: SpotSellFor.All },
  { label: 'Specific Investor(s)', value: SpotSellFor.Specific },
];

export const spotSellForLookup = keyBy(spotSellForValues, 'value');
