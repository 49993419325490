import { Validate, stringValidator } from 'common/form/validations';
import { CreateCustomerForm } from './createCustomerPageTypes';

const validateCustomerForm: Validate<CreateCustomerForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.name, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.idNumber, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.contactNumber, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.address, 1),
      stringValidator.required(),
    );

  return validator;
};

export default validateCustomerForm;
