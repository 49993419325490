import {
  Validate, stringValidator, numberValidator, dateValidator,
} from 'common/form/validations';
import { CloseInvestmentForm } from './closeInvestmentPageTypes';
import { InvestmentCloseType } from './investmentCloseType';

const validateCloseInvestmentForm: Validate<CloseInvestmentForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof(form.effectiveDate),
      dateValidator.required(),
    )
    .validateField(
      nameof(form.maturityDate),
      dateValidator.required(),
    );

  if (form.type === InvestmentCloseType.Reinvest) {
    validator
      .validateField(
        nameof(form.reinvestAmount),
        stringValidator.required(),
        numberValidator.gt(0),
      );

    if (form.reinvestAmount > form.investorPayable) {
      validator
        .validateField(
          nameof(form.exchangeNetwork),
          stringValidator.required(),
        )
        .validateField(
          nameof(form.walletAddress),
          stringValidator.required(),
        )
        .validateField(
          nameof(form.transactionHash),
          stringValidator.required(),
        );
    }
  }

  return validator;
};

export default validateCloseInvestmentForm;
