import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from 'redux';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api';
import toastActions from 'common/ui/Toast/toastActions';
import { createCustomerActions } from './createCustomerPageSlice';
import { mapCreateCustomerFromApi, mapCreateCustomerToApi } from './createCustomerPageMapper';
import { CreateCustomerFormRemote } from './createCustomerPageTypes';

function* onInit(action: Action): Generator {
  if (!createCustomerActions.init.match(action)) { return; }
  yield put(createCustomerActions.setInitialState());

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    createCustomerActions.setPageLoading,
  );

  const { id } = action.payload;
  try {
    if (id) {
      yield put(loadingStart());
      const result = yield* call(
        ApiService.get<CreateCustomerFormRemote>(),
        `/v1/customers/${id}`,
      );

      yield put(createCustomerActions.setForm(
        mapCreateCustomerFromApi(result),
      ));
    }
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!createCustomerActions.submit.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    createCustomerActions.setFormLoading,
  );

  try {
    yield put(loadingStart());

    const isEdit = action.payload.id > 0;
    const result = yield* call(
      isEdit ? ApiService.put<{ id: number }>() : ApiService.post<{ id: number }>(),
      isEdit ? `/v1/customers/${action.payload.id}` : '/v1/customers',
      mapCreateCustomerToApi(action.payload),
    );
    toastActions.success(isEdit
      ? 'Customer is updated successfully.'
      : 'Customer is created successfully.');
    yield put(loadingSuccess('', result.id));
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(createCustomerActions.init.type, onInit);
  yield takeLatest(createCustomerActions.submit.type, onSubmit);
}
