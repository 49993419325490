import * as React from 'react';
import clsx from 'clsx';

interface ModalTitleProps {
  children: React.ReactNode,
  isCenter?: boolean,
}

const ModalTitle: React.FC<ModalTitleProps> = ({ children, isCenter }) => {
  const classes = clsx(
    'h3',
    'mb-4',
    'flex-grow-1',
    isCenter && 'text-center',
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default ModalTitle;
