import { toast } from 'react-toastify';

export default {
  success: (message: string): void => {
    toast.success(message);
  },
  error: (info: Error|string): void => {
    let message = info;
    if (info instanceof Error) {
      message = info.message;
    }
    toast.error(message);
  },
};
