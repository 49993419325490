import { CreateCustomerForm, CreateCustomerFormRemote } from './createCustomerPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapCreateCustomerToApi = (form: CreateCustomerForm)
  : unknown => ({
  id: form.id,
  name: form.name,
  id_number: form.idNumber,
  contact_number: form.contactNumber,
  address: form.address,
});

export const mapCreateCustomerFromApi = (remote: CreateCustomerFormRemote)
  : CreateCustomerForm => ({
  id: remote.id,
  name: remote.name,
  idNumber: remote.id_number,
  contactNumber: remote.contact_number,
  address: remote.address,
});
