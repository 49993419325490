import { formatDate, fromApiDate } from 'common/util/date';
import {
  SpotQuantityByMaturityForm, SpotQuantityByMaturityRemote, SpotQuantityByMaturityResult,
  SpotQuantityByMaturityTrade,
} from './spotQuantityByMaturityPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const fromSpotQuantityByMaturityRemote = (response: SpotQuantityByMaturityRemote,
  form: SpotQuantityByMaturityForm)
  : SpotQuantityByMaturityResult => ({
  loaded: true,
  date: form.maturityDate,
  investments: response.investments.map((i) => ({
    id: i.id,
    spotFundId: i.spot_fund_id,
    customerName: i.customer_name,
    formNo: i.form_no,
    status: i.status,
    maturityDate: formatDate(fromApiDate(i.maturity_date)),
  })),
  trades: response.trades.map<SpotQuantityByMaturityTrade>((t) => ({
    id: t.id,
    name: t.name,
    remainingQuantity: t.remaining_quantity,
    symbol: t.symbol,
    spotFunds: t.spot_funds.map((sf) => ({
      id: sf.id,
      remainingQuantity: sf.remaining_quantity,
    })),
  })),
});
