import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import { formatDateTime } from 'common/util/date';
import { formatCurrency } from 'common/util/currency';
import CardSummaryBlock, { CardSummaryCurrencyBlock } from 'common/ui/CardSummaryBlock';
import classes from './futureDetailPage.module.scss';
import { futureDetailActions } from './futureDetailPageSlice';
import { FutureDetailPageReduxState } from './futureDetailPageTypes';

const FutureDetailPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: FutureDetailPageReduxState) => s.futureDetail.pageLoading,
  );
  const detail = useSelector(
    (s: FutureDetailPageReduxState) => s.futureDetail.detail,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(futureDetailActions.init());
  }, []);

  return (
    <ContentContainer>
      <PageTitle>Future Account</PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card title="Summary">
          <div className="row">
            <div className="col-md-6">
              <CardSummaryCurrencyBlock
                title="Total Pool"
                value={detail.fundPool}
              />
            </div>
            <div className="col-md-6">
              <CardSummaryBlock title="Total Active Investors">
                {detail.totalActiveInvestors}
              </CardSummaryBlock>
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card title="Settlements">
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Transaction Date</th>
                <th scope="col">Original Pool</th>
                <th scope="col">Profit/Loss</th>
                <th scope="col">Amount</th>
                <th scope="col">Affected Investors</th>
              </tr>
            </thead>
            <tbody>
              {detail.settlements.map((i) => (
                <tr key={i.id}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{formatCurrency(i.appliedAmount, 'two')}</td>
                  <td>{`${formatCurrency(i.percent, 'four')}%`}</td>
                  <td>{formatCurrency(i.amount, 'two')}</td>
                  <td>{i.totalPax}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default FutureDetailPage;
