import { ISagaModule } from 'redux-dynamic-modules-saga';
import customerPortalSaga from './customerPortalPageSaga';
import { customerPortalReducer } from './customerPortalPageSlice';
import { CustomerPortalPageReduxState } from './customerPortalPageTypes';

export default (): ISagaModule<CustomerPortalPageReduxState> => ({
  id: 'customerPortal',
  reducerMap: {
    customerPortal: customerPortalReducer,
  },
  sagas: [customerPortalSaga],
  retained: true,
});
