import { combineReducers } from 'redux';
import { persistReducer } from 'context/persist/persistSlice';
import { appReducer } from 'context/app/appSlice';
import { userReducer } from 'context/user/userSlice';
import { tradeReducer } from 'context/trade/tradeSlice';

export default combineReducers({
  persist: persistReducer,
  app: appReducer,
  user: userReducer,
  trade: tradeReducer,
});
