import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { SpotSummaryReport, SpotSummaryReportPageState } from './spotSummaryReportPageTypes';

const initialState: SpotSummaryReportPageState = {
  pageLoading: initialLoadingTrueState,
  report: {
    totalCompanyFund: 0,
    totalInvestorFund: 0,
    totalHoldingFund: 0,
    totalCompanyAndHoldingFund: 0,
    totalProfitLossPercent: 0,
    totalProfitLossInclHoldingPercent: 0,
  },
};

const spotSummaryReportSlice = createSlice({
  name: 'spotSummaryReport',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setReport(state, { payload }: PayloadAction<SpotSummaryReport>) {
      state.report = payload;
      return state;
    },
  },
});

export const spotSummaryReportActions = spotSummaryReportSlice.actions;
export const spotSummaryReportReducer = spotSummaryReportSlice.reducer;
