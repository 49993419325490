import { ISagaModule } from 'redux-dynamic-modules-saga';
import createFutureSettlementSaga from './createFutureSettlementPageSaga';
import { createFutureSettlementReducer } from './createFutureSettlementPageSlice';
import { CreateFutureSettlementPageReduxState } from './createFutureSettlementPageTypes';

export default (): ISagaModule<CreateFutureSettlementPageReduxState> => ({
  id: 'createFutureSettlement',
  reducerMap: {
    createFutureSettlement: createFutureSettlementReducer,
  },
  sagas: [createFutureSettlementSaga],
  retained: true,
});
