import clsx from 'clsx';
import * as React from 'react';
import classes from './linkButton.module.scss';

interface LinkButtonProps {
  label: string,
  className?: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

const Button: React.FC<LinkButtonProps> = ({
  className, label, onClick,
}) => (
  <button
    type="button"
    className={clsx('btn-reset', classes['btn-link'], className)}
    onClick={onClick}
  >
    {label}
  </button>
);

export default Button;
