import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

export interface LoginCredential {
  accessToken: string,
  refreshToken: string,
}

export interface User {
  email: string,
  accessToken: string,
  refreshToken: string,
  name: string,
}

export interface UserState {
  isLoggedIn: boolean,
  user: User,
}

const initialState: UserState = {
  isLoggedIn: false,
  user: {
    email: '',
    accessToken: '',
    refreshToken: '',
    name: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginUser: (state, { payload }: PayloadAction<User>) => {
      state.isLoggedIn = true;
      state.user = payload;
      return state;
    },
    logOut: (state, _action: PayloadAction<string>) => state,
    refreshAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.user.accessToken = payload;
      return state;
    },
    clearDataOnLogout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      if (action?.payload?.persist?.user) {
        const { user } = action.payload.persist as UserState;
        state.isLoggedIn = !!user.refreshToken;
        state.user = user;
      }
      return state;
    });
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
