import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { CreateFutureSettlementPageState, CreateFutureSettlementForm } from './createFutureSettlementPageTypes';

const initialState: CreateFutureSettlementPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  form: {
    dateTime: null,
    amount: null,
  },
};

const createFutureSettlementSlice = createSlice({
  name: 'createFutureSettlement',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<CreateFutureSettlementForm>) => state,
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<CreateFutureSettlementForm>) {
      state.form = payload;
      return state;
    },
  },
});

export const createFutureSettlementActions = createFutureSettlementSlice.actions;
export const createFutureSettlementReducer = createFutureSettlementSlice.reducer;
