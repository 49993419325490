import { ISagaModule } from 'redux-dynamic-modules-saga';
import createCustomerSaga from './createCustomerPageSaga';
import { createCustomerReducer } from './createCustomerPageSlice';
import { CreateCustomerPageReduxState } from './createCustomerPageTypes';

export default (): ISagaModule<CreateCustomerPageReduxState> => ({
  id: 'createCustomer',
  reducerMap: {
    createCustomer: createCustomerReducer,
  },
  sagas: [createCustomerSaga],
  retained: true,
});
