import * as React from 'react';
import classes from './formValue.module.scss';

interface FormValueProps {
  label: string,
  value: string,
}

const FormValue: React.FC<FormValueProps> = ({ label, value }) => (
  <div className="form-group">
    <div>{label}</div>
    <div className={classes.value}>{value}</div>
  </div>
);

export default FormValue;
