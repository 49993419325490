import * as React from 'react';

export enum AlertType {
  warning, error, success, info,
}

export interface AlertProps {
  type: AlertType,
  children: React.ReactNode,
}

const getTypeClass = (type: AlertType): string => {
  switch (type) {
    case AlertType.warning:
      return 'warn';
    case AlertType.error:
      return 'danger';
    case AlertType.info:
      return 'info';
    case AlertType.success:
      return 'success';
    default:
      return '';
  }
};

const Alert: React.FC<AlertProps> = ({ type, children }) => (
  <div className={`alert alert-${getTypeClass(type)}`}>
    {children}
  </div>
);

export default Alert;
