import { formatDate, fromApiDate } from 'common/util/date';
import { TradePrice } from 'context/trade/tradeSlice';
import { InvestmentStatus } from '../investment/shared/investmentStatus';
import { CustomerPortalInvestmentRemote, CustomerPorterInvestment } from './customerPortalPageTypes';
import { mapInvestmentDetailSpotBalanceFromApi } from '../investment/detail/investmentDetailPageMapper';
import { InvestmentDetailFuture, InvestmentDetailFutureSettlement } from '../investment/detail/investmentDetailPageTypes';
import { InvestmentDetailRemote } from '../investment/shared/investmentDetailRemote';

const mapFuture = (response: InvestmentDetailRemote): InvestmentDetailFuture => {
  const { future_fund_transactions: futureTrx, spot_fund_transactions: spotTrx } = response;
  const depositTrx = response.future_fund_transactions.find((t) => t.type === 'deposit');

  const settlements = response.future_settlements.reduce<InvestmentDetailFutureSettlement[]>(
    (prev, cur) => {
      // Find mapping transaction.
      const trx = futureTrx.find((t) => t.related_to_id === cur.id && t.type === 'future_settlement')
      || spotTrx.find((t) => t.related_to_id === cur.id && t.type === 'future_settlement');

      let accountAmount = depositTrx?.amount || 0;
      if (prev.length > 0) {
        const lastRecord = prev[prev.length - 1];
        accountAmount = lastRecord.settlementAmount < 0
          ? lastRecord.accountAmount + lastRecord.settlementAmount
          : lastRecord.accountAmount;
      }
      const settlementAmount = trx?.amount || 0;

      // The investment might not be qualified for first settlement.
      if (settlementAmount !== 0) {
        prev.push({
          id: cur.id,
          accountAmount,
          dateTime: fromApiDate(cur.date_time),
          percent: cur.settlement_percent,
          settlementAmount,
        });
      }

      return prev;
    }, [],
  );

  return {
    initialAmount: depositTrx?.amount || 0,
    balanceAmount: response.future_fund_balance,
    settlements,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const mapCustomerPortalInvestmentFromApi = (
  response: CustomerPortalInvestmentRemote,
  prices: Record<string, TradePrice>,
)
  : CustomerPorterInvestment => {
  const result: CustomerPorterInvestment = {
    id: response.id,
    customer: {
      name: response.customer.name,
      address: response.customer.address,
      contactNumber: response.customer.contact_number,
      idNumber: response.customer.id_number,
    },
    status: response.status,
    formNo: response.form_no,
    maturityDate: formatDate(fromApiDate(response.maturity_date)),
    amount: response.amount,
    bonusPayoutRate: response.bonus_payout_rate,
    future: mapFuture(response),
    spotBalance: mapInvestmentDetailSpotBalanceFromApi(response, prices),
  };

  if (result.status === InvestmentStatus.Closed && response.close_summary) {
    result.closeSummary = {
      bonusPayout: response.close_summary.bonus_payout,
      spotValue: response.close_summary.spot_value,
      futureValue: response.close_summary.future_value,
      investorProfit: response.close_summary.investor_profit,
      spotRoi: response.close_summary.spot_roi,
      fairProfit: response.close_summary.fair_profit,
      totalProfit: response.close_summary.total_profit,
      totalPartialPayout: response.close_summary.total_partial_payout,
    };
  }

  return result;
};
