import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call } from 'typed-redux-saga';
import { downloadBinary } from 'common/util/downloadFile';
import ApiService from 'common/api';
import { closeReportActions } from './closeReportPageSlice';
import { mapInvestmentCloseListItemFromApi } from './closeReportPageMapper';
import { InvestmentCloseListItem, InvestmentCloseListItemRemote } from './closeReportPageTypes';

const exportAsExcel = async (summaries: InvestmentCloseListItem[]): Promise<void> => {
  const { default: Excel } = await import('exceljs');

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Investment Close List');

  worksheet.columns = [{
    header: 'Form No.',
    key: nameof(summaries[0].formNo),
    width: 10,
  }, {
    header: 'Customer',
    key: nameof(summaries[0].customerName),
    width: 30,
  }, {
    header: 'Status',
    key: nameof(summaries[0].status),
    width: 20,
  }, {
    header: 'Transaction Date',
    key: nameof(summaries[0].transactionDateTime),
    style: { numFmt: 'dd/mm/yyyy' },
    width: 20,
  }, {
    header: 'Maturity Date',
    key: nameof(summaries[0].maturityDate),
    style: { numFmt: 'dd/mm/yyyy' },
    width: 20,
  }, {
    header: 'Close Date',
    key: nameof(summaries[0].closeDate),
    style: { numFmt: 'dd/mm/yyyy' },
    width: 20,
  }, {
    header: 'Amount',
    key: nameof(summaries[0].amount),
    width: 20,
  }, {
    header: 'Close Amount',
    key: nameof(summaries[0].closeAmount),
    width: 20,
  }, {
    header: 'Company Profit',
    key: nameof(summaries[0].companyProfit),
    width: 20,
  }, {
    header: 'Investor Profit',
    key: nameof(summaries[0].investorProfit),
    width: 20,
  }, {
    header: 'Referral Profit',
    key: nameof(summaries[0].referralProfit),
    width: 20,
  }, {
    header: 'DJ Profit',
    key: nameof(summaries[0].djProfit),
    width: 20,
  }];

  worksheet.addRows(summaries);

  // Highlight unclean records
  summaries.forEach((s, i) => {
    if (!s.isClean) {
      worksheet.getRow(i + 2).fill = {
        type: 'pattern',
        pattern: 'darkVertical',
        fgColor: {
          argb: '00FFCCCC',
        },
      };
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const binary = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  downloadBinary(binary, 'InvestmentCloseList.xlsx');
};

function* onInit(): Generator {
  yield put(closeReportActions.setInitialState());
}

function* onSubmit(): Generator {
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(closeReportActions.setPageLoading);

  yield put(loadingStart());

  try {
    const response = yield* call(ApiService.get<InvestmentCloseListItemRemote[]>(), '/v1/investments/settlement_report');
    const summaries = response.map((r) => mapInvestmentCloseListItemFromApi(r));

    yield* call(exportAsExcel, summaries);
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(closeReportActions.init.type, onInit);
  yield takeLatest(closeReportActions.submit.type, onSubmit);
}
