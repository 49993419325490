import { formatDateTime, fromApiDate } from 'common/util/date';
import { InvestmentCloseSummary, InvestmentCloseSummaryRemote } from './closeInvestmentPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapCloseInvestmentSummaryFromApi = (response: InvestmentCloseSummaryRemote)
  : InvestmentCloseSummary => ({
  id: response.id,
  amount: response.amount,
  bonusPayoutRate: response.bonus_payout_rate,
  companyProfit: response.company_profit,
  customerId: response.customer_id,
  customerName: response.customer_name,
  djProfit: response.dj_profit,
  formNo: response.form_no,
  insertedById: response.insert_by_id,
  investorProfit: response.investor_profit,
  isClean: response.is_clean,
  maturityDate: fromApiDate(response.maturity_date),
  referralName: response.referral_name,
  referralPayoutMode: response.referral_payout_mode,
  referralProfit: response.referral_profit,
  status: response.status,
  totalProfit: response.total_profit,
  transactionDateTime: formatDateTime(fromApiDate(response.transaction_date_time)),
  updatedByid: response.updated_by_id,
  referralDjMode: response.referral_dj_mode,
  finalBalance: response.investor_breakdown.spot_value + response.investor_breakdown.future_value,
  totalPartialPayout: response.total_partial_payout,
  companyBreakdown: {
    bonusPayout: response.company_breakdown.bonus_payout,
    djProfit: response.company_breakdown.dj_profit,
    fairProfit: response.company_breakdown.fair_profit,
    referralProfit: response.company_breakdown.referral_profit,
  },
  investorBreakdown: {
    fairProfit: response.investor_breakdown.fair_profit,
    spotValue: response.investor_breakdown.spot_value,
    spotRoi: response.investor_breakdown.spot_roi,
    futureValue: response.investor_breakdown.future_value,
    bonusPayout: response.investor_breakdown.bonus_payout,
  },
});
