import * as React from 'react';

interface CardProps {
  children: React.ReactNode,
  titleRight?: React.ReactNode,
  title?: string,
}

const Card: React.FC<CardProps> = ({ title, titleRight, children }) => (
  <div className="card">
    <div className="card-body">
      {(title || titleRight) && (
        <div className="d-flex align-items-start mb-3">
          {title && <h3 className="card-title flex-grow-1 m-0">{title}</h3>}
          {titleRight}
        </div>
      )}
      {children}
    </div>
  </div>
);

export default Card;
