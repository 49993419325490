import { createSlice } from '@reduxjs/toolkit';
import { SignInPageState } from './signInPageTypes';

const initialState: SignInPageState = {
  time: 'Loading',
};

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
  },
});

export const signInActions = signInSlice.actions;
export const signInReducer = signInSlice.reducer;
