import * as React from 'react';

const FooterActionContainer: React.FC = ({ children }) => (
  <>
    <hr />
    <div className="d-flex flex-row-reverse w-100">
      {children}
    </div>
  </>
);

export default FooterActionContainer;
