import { ISagaModule } from 'redux-dynamic-modules-saga';
import investmentListSaga from './investmentListPageSaga';
import { investmentListReducer } from './investmentListPageSlice';
import { InvestmentListPageReduxState } from './investmentListPageTypes';

export default (): ISagaModule<InvestmentListPageReduxState> => ({
  id: 'investmentList',
  reducerMap: {
    investmentList: investmentListReducer,
  },
  sagas: [investmentListSaga],
  retained: true,
});
