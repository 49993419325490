import { fromApiDate } from 'common/util/date';
import { TradePrice } from 'context/trade/tradeSlice';
import { SpotDetail, SpotDetailRemote, SpotDetailTransaction } from './spotDetailPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapSpotDetailFromApi = (response: SpotDetailRemote, price: TradePrice)
  : SpotDetail => {
  const buys = response.spot_buys.map<SpotDetailTransaction>((t) => ({
    id: t.id,
    dateTime: fromApiDate(t.date_time),
    type: 'buy',
    quantity: t.quantity,
    unitPrice: t.unit_price,
    total: t.total,
    usePercent: t.use_percent,
  }));

  const sells = response.spot_sells.map<SpotDetailTransaction>((t) => ({
    id: t.id,
    dateTime: fromApiDate(t.date_time),
    type: 'sell',
    quantity: t.quantity,
    unitPrice: t.unit_price,
    total: t.total,
    usePercent: t.use_percent,
  }));

  let trx = [...buys, ...sells];
  trx = trx.sort((a, b) => a.dateTime.diff(b.dateTime).valueOf());

  return {
    id: response.id,
    name: response.name,
    balance: response.balance,
    price: price.price,
    holdingValue: price.price * response.balance,
    symbol: response.symbol,
    color: response.color,
    coingeckoId: response.coingecko_id,
    transactions: trx,
  };
};
