import { ISagaModule } from 'redux-dynamic-modules-saga';
import { signInReducer } from './signInPageSlice';
import { SignInPageReduxState } from './signInPageTypes';

export default (): ISagaModule<SignInPageReduxState> => ({
  id: 'signIn',
  reducerMap: {
    signIn: signInReducer,
  },
  retained: true,
});
