import {
  Validate, stringValidator, dateValidator, numberValidator,
} from 'common/form/validations';
import { SellSpotForm, SpotDetail } from './spotDetailPageTypes';

const validateSellTradeForm = (context: SpotDetail)
  : Validate<SellSpotForm> => ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.dateTime, 1),
      dateValidator.required(),
    )
    .validateField(
      nameof.full(form.unitPrice, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.quantity, 1),
      stringValidator.required(),
      numberValidator.lte(context.balance),
    )
    .validateField(
      nameof.full(form.transactionFee, 1),
      numberValidator.gte(0),
    )
    .validateField(
      nameof.full(form.total, 1),
      stringValidator.required(),
    );

  return validator;
};

export default validateSellTradeForm;
