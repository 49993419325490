import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call, select } from 'typed-redux-saga';
import ApiService from 'common/api';
import { IdLabelLookup } from 'common/util/lookup';
import { Action } from 'redux';
import history from 'common/setup/history';
import toastActions from 'common/ui/Toast/toastActions';
import { CreateTradePageReduxState } from './createTradePageTypes';
import { createTradeActions } from './createTradePageSlice';
import { mapCreateTradeToApi } from './createTradePageMapper';

function* onInit(): Generator {
  yield put(createTradeActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(createTradeActions.setPageLoading);
  try {
    const trades = yield* call(ApiService.get<IdLabelLookup[]>(), '/v1/trades/lookup');
    yield put(createTradeActions.setTrades(trades));

    // Default form to BTC/USDT
    const defaultTradeId = trades.find((t) => t.label === 'BTC/USDT')?.value;
    if (defaultTradeId) {
      let form = yield* select((s: CreateTradePageReduxState) => s.createTrade.form);
      form = {
        ...form, tradeId: defaultTradeId,
      };

      yield put(createTradeActions.setForm(form));
    }

    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!createTradeActions.submitForm.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    createTradeActions.setFormLoading,
  );

  try {
    yield put(loadingStart());

    const form = mapCreateTradeToApi(action.payload);

    yield* call(ApiService.post<{ id: number }>(), '/v1/spot-trades', form);
    yield put(loadingSuccess());

    toastActions.success('Spot Trade is created successfully.');
    history.push(`/member/spot-trade/detail/${action.payload.tradeId}`);
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(createTradeActions.init.type, onInit);
  yield takeLatest(createTradeActions.submitForm.type, onSubmit);
}
