import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from '@reduxjs/toolkit';
import { userActions } from 'context/user/userSlice';
import { signInCallbackActions } from './signInCallbackPageSlice';

function* onInit(action: Action): Generator {
  if (!signInCallbackActions.init.match(action)) {
    return;
  }

  yield put(signInCallbackActions.setInitialState());
  const [, , loadingSuccess] = bindLoadingActions(signInCallbackActions.setPageLoading);
  const { payload } = action;
  let isSuccess = true;

  if (payload.s === 'fail' || payload.s === 'unauthorized') {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(userActions.setLoginUser({
      email: payload.email,
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken,
      name: payload.name,
    }));
    yield put(loadingSuccess(undefined, '/member/dashboard'));
  } else {
    yield put(loadingSuccess(undefined, `/auth/sign-in?from=${payload.s}`));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(signInCallbackActions.init.type, onInit);
}
