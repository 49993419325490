import * as React from 'react';
import logo from 'assets/images/logo.png';
import appConfig from 'common/setup/config';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import classes from './appContainer.module.scss';

const AppContainer: React.FC = ({ children }) => (
  <div>
    <nav className={clsx(
      `navbar navbar-light sticky-top ${classes.nav}`,
      appConfig.appVersion === 2 && classes['nav-v2'],
    )}
    >
      <Link className="navbar-brand" to="/member/dashboard">
        <img src={logo} className={classes.logo} alt="Logo" />
        <span className={`${classes.title}`}>
          {appConfig.appEnv === 'development' && 'Dev'}
          {appConfig.appEnv === 'staging' && 'Staging'}
          {appConfig.appEnv === 'prod' && 'Renevue Tracking System'}
        </span>
      </Link>
    </nav>
    <div className="container">
      {children}
    </div>
  </div>
);

export default AppContainer;
