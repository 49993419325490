import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import getLocalId from 'common/util/getLocalId';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { CreateCustomerPageState, CreateCustomerForm, CreateCustomerPageParam } from './createCustomerPageTypes';

const initialForm: CreateCustomerForm = {
  id: getLocalId(),
  name: '',
  idNumber: '',
  contactNumber: '',
  address: '',
};

const initialState: CreateCustomerPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  form: initialForm,
};

const createCustomerSlice = createSlice({
  name: 'createCustomer',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<CreateCustomerPageParam>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<CreateCustomerForm>) {
      state.form = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<CreateCustomerForm>) => state,
  },
});

export const createCustomerActions = createCustomerSlice.actions;
export const createCustomerReducer = createCustomerSlice.reducer;
