import {
  put, takeLatest,
} from 'redux-saga/effects';
import { userActions } from './userSlice';

function* onLogout(): Generator {
  yield put(userActions.clearDataOnLogout());
}

export default function* mainSaga(): Generator {
  yield takeLatest(userActions.logOut.type, onLogout);
}
