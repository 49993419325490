import { fromApiDate } from 'common/util/date';
import { investmentStatusLookup } from '../../investment/shared/investmentStatus';
import { InvestmentCloseListItem, InvestmentCloseListItemRemote } from './closeReportPageTypes';

const convertDate = (remoteDate: string): Date => {
  // Date workaround as the exceljs read every date as UTC
  const luxonDate = fromApiDate(remoteDate);
  return luxonDate.plus({ minutes: luxonDate.offset }).toJSDate();
};

// eslint-disable-next-line import/prefer-default-export
export const mapInvestmentCloseListItemFromApi = (response: InvestmentCloseListItemRemote)
  : InvestmentCloseListItem => ({
  id: response.id,
  amount: response.amount,
  isClean: response.is_clean,
  statusRaw: response.status,
  status: investmentStatusLookup[response.status].label,
  companyProfit: response.company_profit,
  customerName: response.customer_name,
  djProfit: response.dj_profit,
  formNo: response.form_no,
  investorProfit: response.investor_profit,
  maturityDate: convertDate(response.maturity_date),
  transactionDateTime: convertDate(response.transaction_date_time),
  closeAmount: response.close_amount,
  closeDate: response.close_date
    ? convertDate(response.close_date)
    : null,
  referralProfit: response.referral_profit,
});
