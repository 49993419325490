import { LoginCredential, userActions } from 'context/user/userSlice';
import { Dispatch, AnyAction, Middleware } from 'redux';
import { Action } from '@reduxjs/toolkit';
import { appActions } from 'context/app/appSlice';
import { ContextReduxState } from 'context/contextTypes';

const initialCredentials: LoginCredential = {
  accessToken: '',
  refreshToken: '',
};
let credentials = initialCredentials;

// Dispatching an action when the API returns 401 and refresh token attempt failed.
let dispatchLocal: Dispatch<AnyAction> | undefined;
export const dispatchLogout: () => void = () => dispatchLocal && dispatchLocal(userActions.logOut('unauthorized'));

export const updateUserCredentials: (newAccessToken: string) => void = (
  newAccessToken,
) => {
  credentials = {
    ...credentials,
    accessToken: newAccessToken,
  };
  if (dispatchLocal) {
    dispatchLocal(userActions.refreshAccessToken(newAccessToken));
  }
};

export const getUserCredentials: () => LoginCredential = () => credentials;

// Redux middleware to get latest user credential to be used for calling API.
const credentialMiddleware: Middleware<unknown, ContextReduxState> = (
  { getState, dispatch },
) => (next) => (action: Action) => {
  if (!dispatchLocal) {
    dispatchLocal = dispatch;
  }

  if (userActions.setLoginUser.match(action)) {
    credentials = {
      accessToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken,
    };
  } if (appActions.initApp.match(action)) {
    const state = getState().context.user;
    credentials = {
      accessToken: state.user.accessToken,
      refreshToken: state.user.refreshToken,
    };
  } if (userActions.clearDataOnLogout.match(action)) {
    credentials = initialCredentials;
  }

  return next(action);
};

export default credentialMiddleware;
