import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { ModalPromptButton, ModalPromptMessage } from 'common/ui/Modal';
import { InvestmentDetailPageReduxState } from './investmentDetailPageTypes';
import { investmentDetailActions } from './investmentDetailPageSlice';

const InvestmentPendingSettlementModal: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector((s: InvestmentDetailPageReduxState) => s
    .investmentDetail.pendingSettlementLoading);
  const prompt = useSelector((s: InvestmentDetailPageReduxState) => s
    .investmentDetail.pendingSettlementPrompt);

  React.useEffect(() => {
    if (loading.isSuccess) {
      dispatch(investmentDetailActions.setPendingSettlementPrompt());
    }
  }, [loading]);

  return (
    <Modal
      size="small"
      isOpen={prompt.id > 0}
    >
      <ModalPromptMessage>
        {`Are you sure you want to set the investment '${prompt.name}' as Pending Settlement?`}
      </ModalPromptMessage>
      <ModalPromptMessage>
        The user would not be assigned any new spot but and
        would be excluded from future settlements after this.
      </ModalPromptMessage>
      <ModalPromptButton
        isLoading={loading.isLoading}
        yesLabel="Confirm"
        noLabel="Cancel"
        onYesClick={() => dispatch(investmentDetailActions.confirmPendingSettlement())}
        onNoClick={() => dispatch(investmentDetailActions.setPendingSettlementPrompt())}
      />
    </Modal>
  );
};

export default InvestmentPendingSettlementModal;
