import {
  Validate, dateValidator, numberValidator, stringValidator,
} from 'common/form/validations';
import { SellAllForm, SpotQuantityByMaturityForm } from './spotQuantityByMaturityPageTypes';

export const validateSellAllForm
  : Validate<SellAllForm> = ({ validator, form }) => {
    validator
      .validateField(
        nameof.full(form.dateTime, 1),
        dateValidator.required(),
      )
      .validateField(
        nameof.full(form.unitPrice, 1),
        stringValidator.required(),
        numberValidator.gte(0),
      )
      .validateField(
        nameof.full(form.transactionFee, 1),
        stringValidator.required(),
        numberValidator.gte(0),
      )
      .validateField(
        nameof.full(form.total, 1),
        stringValidator.required(),
        numberValidator.gte(0),
      );

    return validator;
  };

const validateSpotQuantityByMaturityForm
  : Validate<SpotQuantityByMaturityForm> = ({ validator, form }) => {
    validator
      .validateField(
        nameof.full(form.maturityDate, 1),
        dateValidator.required(),
      );

    return validator;
  };

export default validateSpotQuantityByMaturityForm;
