import { ISagaModule } from 'redux-dynamic-modules-saga';
import investmentDetailSaga from './investmentDetailPageSaga';
import { investmentDetailReducer } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState } from './investmentDetailPageTypes';

export default (): ISagaModule<InvestmentDetailPageReduxState> => ({
  id: 'investmentDetail',
  reducerMap: {
    investmentDetail: investmentDetailReducer,
  },
  sagas: [investmentDetailSaga],
  retained: true,
});
