import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { SignInCallbackPageQueryString, SignInCallbackPageState } from './signInCallbackPageTypes';

const initialState: SignInCallbackPageState = {
  pageLoading: initialLoadingTrueState,
};

// SignInCallbackPageQueryString
const signInCallbackSlice = createSlice({
  name: 'signInCallback',
  initialState,
  reducers: {
    init: (state, _action: PayloadAction<SignInCallbackPageQueryString>) => state,
    setInitialState() {
      return initialState;
    },
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
  },
});

export const signInCallbackActions = signInCallbackSlice.actions;
export const signInCallbackReducer = signInCallbackSlice.reducer;
