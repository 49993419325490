import * as React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export interface NumberFieldProps {
  name?: string,
  id?: string,
  className?: string,
  placeholder?: string,
  disabled?: boolean,
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined,
  onValueChange: (values: NumberFormatValues) => void,
  decimal?: number,
  value: string | number | null | undefined,
}

const NumberField: React.FC<NumberFieldProps> = ({
  name,
  id,
  className,
  placeholder,
  disabled,
  onBlur,
  onValueChange,
  decimal,
  value,
}) => (
  <NumberFormat
    thousandSeparator
    decimalScale={decimal || 0}
    name={name}
    className={className}
    id={id}
    placeholder={placeholder}
    onValueChange={onValueChange}
    onBlur={onBlur}
    value={value}
    disabled={disabled}
    autoComplete="off"
  />
);

export default NumberField;
