import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import ApiService from 'common/api';
import { toApiDateTime } from 'common/util/date';
import toastActions from 'common/ui/Toast/toastActions';
import history from 'common/setup/history';
import { Action } from 'redux';
import { call } from 'typed-redux-saga';
import { createFutureSettlementActions } from './createFutureSettlementPageSlice';

function* onInit(): Generator {
  yield put(createFutureSettlementActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    createFutureSettlementActions.setPageLoading,
  );
  try {
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!createFutureSettlementActions.submit.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    createFutureSettlementActions.setFormLoading,
  );
  yield put(loadingStart());

  const form = action.payload;

  try {
    yield* call(ApiService.post(), '/v1/futures/settlement', {
      date_time: toApiDateTime(form.dateTime),
      settlement_amount: form.amount,
    });

    toastActions.success('Transferred to future fund successfully.');
    history.push('/member/future/detail');
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(createFutureSettlementActions.init.type, onInit);
  yield takeLatest(createFutureSettlementActions.submit.type, onSubmit);
}
