import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowUpIcon, XIcon } from '@primer/octicons-react';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import Modal, { ModalTitle } from 'common/ui/Modal';
import Pagination from 'common/ui/Pagination';
import { PageScrollDirection } from 'common/util/list';
import Button, { IconButton } from 'common/ui/Button';
import produce from 'immer';
import { NumberField } from 'common/form';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Big from 'big.js';
import classes from './selectSpotSellInvestmentModal.module.scss';
import { selectSpotSellInvestmentActions } from './selectSpotSellInvestmentModalSlice';
import { SelectSpotSellInvestmentModalReduxState } from './selectSpotSellInvestmentModalTypes';

const SelectSpotSellInvestmentModal: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SelectSpotSellInvestmentModalReduxState) => s.selectSpotSellInvestment.pageLoading,
  );
  const isOpen = useSelector(
    (s: SelectSpotSellInvestmentModalReduxState) => s.selectSpotSellInvestment.isOpen,
  );
  const list = useSelector(
    (s: SelectSpotSellInvestmentModalReduxState) => s.selectSpotSellInvestment.list,
  );
  const selected = useSelector(
    (s: SelectSpotSellInvestmentModalReduxState) => s.selectSpotSellInvestment.selected,
  );

  React.useEffect(() => () => {
    dispatch(selectSpotSellInvestmentActions.setInitialState());
  }, []);

  const summary = React.useMemo(() => {
    const all = Object.values(selected);
    const totalQuantity = all.reduce((prev, cur) => prev.add(cur.sellQuantity), new Big(0));
    const investmentCount = all.filter((a) => a.sellQuantity > 0).length;

    return {
      totalQuantity,
      investmentCount,
    };
  }, [selected]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dispatch(selectSpotSellInvestmentActions.commit(false))}
      size="large"
    >
      <div className={classes.wrap}>
        <div className="d-flex flex-direction-row align-items-start">
          <ModalTitle>
            Select Investment
          </ModalTitle>
          <IconButton
            icon={XIcon}
            size="medium"
            onClick={() => dispatch(selectSpotSellInvestmentActions.commit(false))}
          />
        </div>
        <LoadingSwitch loading={pageLoading}>
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Form No.</th>
                <th scope="col">Customer</th>
                <th scope="col">Maturity Date</th>
                <th scope="col">Avail Qty.</th>
                <th scope="col">Sell Qty.</th>
              </tr>
            </thead>
            <tbody>
              {list.data.map((i) => (
                <tr key={i.id}>
                  <th scope="row">{i.formNo}</th>
                  <td>{i.customerName}</td>
                  <td>{i.maturityDate}</td>
                  <td>{i.remainingQuantity}</td>
                  <td>
                    <div className={classes['input-column']}>
                      <NumberField
                        value={selected[i.id]?.sellQuantity}
                        decimal={6}
                        onValueChange={(values) => {
                          const newValue = values.floatValue !== undefined
                            ? values.floatValue
                            : 0;

                          const newSelected = produce(selected, (draft) => {
                            if (!draft[i.id]) {
                              draft[i.id] = {
                                ...i,
                                sellQuantity: newValue,
                              };
                            } else {
                              draft[i.id].sellQuantity = newValue;
                            }
                          });
                          dispatch(selectSpotSellInvestmentActions.setSelected(newSelected));
                        }}
                        onBlur={() => {
                          if (selected[i.id]?.sellQuantity > i.remainingQuantity) {
                            const newSelected = produce(selected, (draft) => {
                              draft[i.id].sellQuantity = i.remainingQuantity;
                            });
                            dispatch(selectSpotSellInvestmentActions.setSelected(newSelected));
                          }
                        }}
                      />
                      <IconButton
                        icon={ArrowUpIcon}
                        onClick={() => {
                          const newSelected = produce(selected, (draft) => {
                            if (!draft[i.id]) {
                              draft[i.id] = {
                                ...i,
                                sellQuantity: i.remainingQuantity,
                              };
                            } else {
                              draft[i.id].sellQuantity = i.remainingQuantity;
                            }
                          });
                          dispatch(selectSpotSellInvestmentActions.setSelected(newSelected));
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            hasNextPage={list.info.hasNextPage}
            hasPrevPage={list.info.hasPreviousPage}
            onNextPageClick={() => dispatch(
              selectSpotSellInvestmentActions.page(PageScrollDirection.Next),
            )}
            onPrevPageClick={() => dispatch(
              selectSpotSellInvestmentActions.page(PageScrollDirection.Previous),
            )}
          />
        </LoadingSwitch>
        <FooterActionContainer>
          <Button
            label="Save"
            onClick={() => dispatch(
              dispatch(selectSpotSellInvestmentActions.commit(true)),
            )}
          />
          <div className="mx-2" />
          <Button label="Cancel" secondary onClick={() => dispatch(selectSpotSellInvestmentActions.commit(false))} />
          <div className="mx-2" />
          <div className="flex-grow-1">
            {'Investment(s) Selected: '}
            <strong>{summary.investmentCount}</strong>
            <br />
            {'Total Quantity: '}
            <strong>{summary.totalQuantity.toNumber()}</strong>
          </div>
        </FooterActionContainer>
      </div>
    </Modal>
  );
};

export default SelectSpotSellInvestmentModal;
