import * as React from 'react';
import classes from './labelValueRow.module.scss';

interface LabelValueRowProps {
  label: string,
  children: React.ReactNode,
}

const LabelValueRow: React.FC<LabelValueRowProps> = ({
  label, children,
}) => (
  <div className="d-flex">
    <div className={classes.label}>
      <span>{label}</span>
      <span>:</span>
    </div>
    <div className={classes.value}>
      {children}
    </div>
  </div>
);

export default LabelValueRow;
