import { Validate, stringValidator } from 'common/form/validations';
import { CustomerLoginForm } from './customerPortalPageTypes';

const validateCustomerLoginForm: Validate<CustomerLoginForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.formNo, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.password, 1),
      stringValidator.required(),
    );

  return validator;
};

export default validateCustomerLoginForm;
