import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { FutureDetail, FutureDetailPageState } from './futureDetailPageTypes';

const initialState: FutureDetailPageState = {
  pageLoading: initialLoadingTrueState,
  detail: {
    fundPool: 0,
    totalActiveInvestors: 0,
    settlements: [],
  },
};

const futureDetailSlice = createSlice({
  name: 'futureDetail',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<FutureDetail>) {
      state.detail = payload;
      return state;
    },
  },
});

export const futureDetailActions = futureDetailSlice.actions;
export const futureDetailReducer = futureDetailSlice.reducer;
