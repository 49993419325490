import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory } from 'react-router';
import Card from 'common/ui/Card';
import { FormikDatePicker, FormikForm } from 'common/form';
import { DateTime } from 'luxon';
import Button, { LinkButton } from 'common/ui/Button';
import { Link } from 'react-router-dom';
import { investmentStatusLookup } from '../../investment/shared/investmentStatus';
import { SpotQuantityByMaturityForm, SpotQuantityByMaturityPageReduxState } from './spotQuantityByMaturityPageTypes';
import { spotQuantityByMaturityActions } from './spotQuantityByMaturityPageSlice';
import classes from './spotQuantityByMaturityPage.module.scss';
import validateSpotQuantityByMaturityForm from './spotQuantityByMaturityPageValidation';
import SellAllModal from './SellAllModal';

const SpotQuantityByMaturityPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SpotQuantityByMaturityPageReduxState) => s.spotQuantityByMaturity.pageLoading,
  );
  const result = useSelector(
    (s: SpotQuantityByMaturityPageReduxState) => s.spotQuantityByMaturity.result,
  );

  const initialForm = React.useMemo<SpotQuantityByMaturityForm>(() => ({
    maturityDate: DateTime.local().plus({ month: 1 }).endOf('month'),
  }), []);

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(spotQuantityByMaturityActions.init(initialForm));
  }, []);

  return (
    <ContentContainer>
      <SellAllModal />
      <PageTitle
        onBack={() => history.goBack()}
      >
        Spot Quantity by Maturity Date
      </PageTitle>
      <Card title="Parameters">
        <FormikForm
          initialValues={initialForm}
          onSubmit={(form) => {
            dispatch(spotQuantityByMaturityActions.submit(form));
          }}
          validate={validateSpotQuantityByMaturityForm}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              {/* <LoadingAlert loading={formLoading} /> */}
              <div className="row">
                <div className="col-12">
                  <FormikDatePicker
                    id={nameof(values.maturityDate)}
                    name={nameof(values.maturityDate)}
                    label="Maturity Date"
                  />
                </div>
                <div className="col-12">
                  <Button type="submit" label="Search" />
                </div>
              </div>
            </form>
          )}
        </FormikForm>
      </Card>
      <div className="my-3" />
      <LoadingSwitch loading={pageLoading}>
        {result.loaded && (
          <>
            <Card title="Trade List">
              <table className="table table-hover table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Avail. Quantity</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {result.trades.map((t) => (
                    <tr key={t.id}>
                      <th scope="row">
                        {t.symbol}
                      </th>
                      <td>{t.remainingQuantity}</td>
                      <td>
                        <LinkButton
                          label="Sell All"
                          onClick={() => dispatch(spotQuantityByMaturityActions.setSellAllPrompt({
                            tradeId: t.id,
                            quantity: t.remainingQuantity,
                            name: t.symbol,
                            spotFunds: t.spotFunds,
                          }))}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
            <div className="my-3" />
            <Card title="Investments Included">
              <table className="table table-hover table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col">Form No.</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Status</th>
                    <th scope="col">Maturity Date</th>
                  </tr>
                </thead>
                <tbody>
                  {result.investments.map((i) => (
                    <tr key={i.id}>
                      <th scope="row">
                        <Link to={`/member/investment/detail/${i.id}`}>
                          {i.formNo}
                        </Link>
                      </th>
                      <td>{i.customerName}</td>
                      <td>{investmentStatusLookup[i.status].label}</td>
                      <td>{i.maturityDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          </>
        )}
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default SpotQuantityByMaturityPage;
