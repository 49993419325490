import { fromApiDate, toApiDateTime } from 'common/util/date';
import { InvestmentDetailRemote } from '../shared/investmentDetailRemote';
import { EditInvestmentForm } from './editInvestmentPageTypes';

export const mapEditInvestmentFromApi = (remote: InvestmentDetailRemote)
  : EditInvestmentForm => ({
  id: remote.id,
  formNo: remote.form_no,
  amount: remote.amount,
  bonusPayoutRate: remote.bonus_payout_rate,
  customerId: remote.customer_id,
  exchangeNetwork: remote.exchange_network,
  maturityDate: fromApiDate(remote.maturity_date),
  referralDjFee: remote.referral_dj_fee,
  referralDjMode: remote.referral_dj_mode,
  referralExchangeNetwork: remote.referral_exchange_network,
  referralFee: remote.referral_fee,
  referralName: remote.referral_name,
  referralPayoutMode: remote.referral_payout_mode,
  referralTransactionDateTime: fromApiDate(remote.referral_transaction_date_time),
  referralTransactionHash: remote.referral_transaction_hash,
  referralWalletAddress: remote.referral_wallet_address,
  transactionDateTime: fromApiDate(remote.transaction_date_time),
  transactionHash: remote.transaction_hash,
  walletAddress: remote.wallet_address,
});

export const mapEditInvestmentToApi = (form: EditInvestmentForm)
  : unknown => ({
  id: form.id,
  customer_id: form.customerId,
  exchange_network: form.exchangeNetwork,
  wallet_address: form.walletAddress,
  transaction_hash: form.transactionHash,
  bonus_payout_rate: form.bonusPayoutRate,

  referral_name: form.referralName,
  referral_transaction_date_time: toApiDateTime(form.referralTransactionDateTime),
  referral_exchange_network: form.referralExchangeNetwork,
  referral_wallet_address: form.referralWalletAddress,
  referral_transaction_hash: form.referralTransactionHash,
});
