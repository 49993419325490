import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import {
  SellSpotForm, SpotDetail, SpotDetailPageParam, SpotDetailPageState,
} from './spotDetailPageTypes';

const initialState: SpotDetailPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  detail: {
    id: 0,
    balance: 0,
    holdingValue: 0,
    price: 0,
    coingeckoId: '',
    color: '#000',
    name: '',
    symbol: '',
    transactions: [],
  },
};

const spotDetailSlice = createSlice({
  name: 'spotDetail',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<SpotDetailPageParam>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<SpotDetail>) {
      state.detail = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<SellSpotForm>) => state,
  },
});

export const spotDetailActions = spotDetailSlice.actions;
export const spotDetailReducer = spotDetailSlice.reducer;
