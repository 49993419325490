import * as React from 'react';
import { connect, getIn } from 'formik';
import { ConnectedFormikProps } from './formikTypes';

interface FormikTextFieldProps {
  name: string,
}

const FormikTextField: React.FC<FormikTextFieldProps & ConnectedFormikProps> = ({
  formik,
  name,
}) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const isInvalid = error && touch;

  return isInvalid ? (
    <div className="invalid-feedback force-show">{error}</div>
  ) : null;
};

export default connect<FormikTextFieldProps, unknown>(FormikTextField);
