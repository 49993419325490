import { ISagaModule } from 'redux-dynamic-modules-saga';
import spotListSaga from './spotListPageSaga';
import { spotListReducer } from './spotListPageSlice';
import { SpotListPageReduxState } from './spotListPageTypes';

export default (): ISagaModule<SpotListPageReduxState> => ({
  id: 'spotList',
  reducerMap: {
    spotList: spotListReducer,
  },
  sagas: [spotListSaga],
  retained: true,
});
