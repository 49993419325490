import {
  put, takeLatest,
} from 'redux-saga/effects';
import keyBy from 'lodash/keyBy';
import { call, SagaGenerator, select } from 'typed-redux-saga';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from 'redux';
import ApiService from 'common/api';
import {
  getListRequestQueryString, List, mapRemoteList, PageScrollDirection, RemoteList,
} from 'common/util/list';
import { formatDate, fromApiDate } from 'common/util/date';
import Big from 'big.js';
import { selectSpotSellInvestmentActions } from './selectSpotSellInvestmentModalSlice';
import { SelectSpotSellInvestmentModalReduxState, TradeRemainingQuantity, TradeRemainingQuantityRemote } from './selectSpotSellInvestmentModalTypes';

function* loadList(direction: PageScrollDirection): SagaGenerator<List<TradeRemainingQuantity>> {
  const list = yield* select((s: SelectSpotSellInvestmentModalReduxState) => s
    .selectSpotSellInvestment.list);
  const tradeId = yield* select((s: SelectSpotSellInvestmentModalReduxState) => s
    .selectSpotSellInvestment.tradeId);
  const queryString = getListRequestQueryString(list.info, direction);

  const resp = yield* call(ApiService.get<RemoteList<TradeRemainingQuantityRemote>>(), `/v1/spot-trades/remaining_quantity_by_trade?trade_id=${tradeId}&${queryString}`);

  const result = mapRemoteList(
    resp,
    (remote) => remote.map<TradeRemainingQuantity>((l) => ({
      id: l.id,
      formNo: l.form_no,
      customerName: l.customer_name,
      spotFundId: l.spot_fund_id,
      maturityDate: formatDate(fromApiDate(l.maturity_date)),
      remainingQuantity: l.remaining_quantity,
    })),
  );

  return result;
}

function* onPage(action: Action): Generator {
  if (!selectSpotSellInvestmentActions.page.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    selectSpotSellInvestmentActions.setPageLoading,
  );
  yield put(loadingStart());

  try {
    const list = yield* call(loadList, action.payload);
    yield put(selectSpotSellInvestmentActions.setList(list));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onInit(action: Action): Generator {
  if (!selectSpotSellInvestmentActions.init.match(action)) { return; }

  yield put(selectSpotSellInvestmentActions.setInitialState());
  yield put(selectSpotSellInvestmentActions.setIsOpen(true));
  yield put(selectSpotSellInvestmentActions.setTradeId(action.payload.tradeId));
  yield put(selectSpotSellInvestmentActions.setCallbackFunc(action.payload.callback));
  if (action.payload.lastResult.isConfirm) {
    yield put(selectSpotSellInvestmentActions.setSelected(
      keyBy(action.payload.lastResult.selected, 'id'),
    ));
  }

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    selectSpotSellInvestmentActions.setPageLoading,
  );

  try {
    const list = yield* call(loadList, PageScrollDirection.First);
    yield put(selectSpotSellInvestmentActions.setList(list));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onCommit(action: Action): Generator {
  if (!selectSpotSellInvestmentActions.commit.match(action)) { return; }

  const selected = yield* select((s: SelectSpotSellInvestmentModalReduxState) => s
    .selectSpotSellInvestment.selected);
  const callbackFunc = yield* select((s: SelectSpotSellInvestmentModalReduxState) => s
    .selectSpotSellInvestment.callbackFunc);

  const all = Object.values(selected);
  const totalQuantity = all.reduce((prev, cur) => prev.add(cur.sellQuantity), new Big(0));
  const investmentCount = all.filter((a) => a.sellQuantity > 0).length;

  if (action.payload) {
    callbackFunc({
      isConfirm: true,
      selected: Object.values(selected).filter((s) => s.remainingQuantity > 0),
      investmentCount,
      totalQuantity: totalQuantity.toNumber(),
    });
  } else {
    callbackFunc({ isConfirm: false });
  }

  yield put(selectSpotSellInvestmentActions.setInitialState());
}

export default function* mainSaga(): Generator {
  yield takeLatest(selectSpotSellInvestmentActions.init.type, onInit);
  yield takeLatest(selectSpotSellInvestmentActions.page.type, onPage);
  yield takeLatest(selectSpotSellInvestmentActions.commit.type, onCommit);
}
