import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import {
  SellAllForm,
  SellAllPrompt, SpotQuantityByMaturityForm, SpotQuantityByMaturityPageState,
  SpotQuantityByMaturityResult,
} from './spotQuantityByMaturityPageTypes';

const initialSellAllPrompt: SellAllPrompt = {
  tradeId: 0,
  name: '',
  quantity: 0,
  spotFunds: [],
};

const initialState: SpotQuantityByMaturityPageState = {
  pageLoading: initialLoadingTrueState,
  result: {
    loaded: false,
    date: null,
    investments: [],
    trades: [],
  },
  sellAllPrompt: initialSellAllPrompt,
  sellAllLoading: initialLoadingState,
};

const spotQuantityByMaturitySlice = createSlice({
  name: 'spotQuantityByMaturity',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<SpotQuantityByMaturityForm>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setResult(state, { payload }: PayloadAction<SpotQuantityByMaturityResult>) {
      state.result = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<SpotQuantityByMaturityForm>) => state,
    setSellAllPrompt(state, { payload }: PayloadAction<SellAllPrompt|undefined>) {
      state.sellAllPrompt = payload || initialSellAllPrompt;
      return state;
    },
    setSellAllLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.sellAllLoading = payload;
      return state;
    },
    submitSellAll: (state, _action: PayloadAction<SellAllForm>) => state,
  },
});

export const spotQuantityByMaturityActions = spotQuantityByMaturitySlice.actions;
export const spotQuantityByMaturityReducer = spotQuantityByMaturitySlice.reducer;
