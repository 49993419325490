import { ISagaModule } from 'redux-dynamic-modules-saga';
import createTradeSaga from './createTradePageSaga';
import { createTradeReducer } from './createTradePageSlice';
import { CreateTradePageReduxState } from './createTradePageTypes';

export default (): ISagaModule<CreateTradePageReduxState> => ({
  id: 'createTrade',
  reducerMap: {
    createTrade: createTradeReducer,
  },
  sagas: [createTradeSaga],
  retained: true,
});
