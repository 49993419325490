import { ISagaModule } from 'redux-dynamic-modules-saga';
import spotSummaryReportSaga from './spotSummaryReportPageSaga';
import { spotSummaryReportReducer } from './spotSummaryReportPageSlice';
import { SpotSummaryReportPageReduxState } from './spotSummaryReportPageTypes';

export default (): ISagaModule<SpotSummaryReportPageReduxState> => ({
  id: 'spotSummaryReport',
  reducerMap: {
    spotSummaryReport: spotSummaryReportReducer,
  },
  sagas: [spotSummaryReportSaga],
  retained: true,
});
