import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import history from 'common/setup/history';
import {
  FormikDatePicker,
  FormikDropDownList, FormikForm, FormikNumberField, FormikTextField,
} from 'common/form';
import { LoadingAlert } from 'common/ui/Alert';
import Card from 'common/ui/Card';
import { useParams } from 'react-router';
import FormValue from 'common/ui/FormValue';
import { formatDate, formatDateTime } from 'common/util/date';
import { formatCurrency } from 'common/util/currency';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button from 'common/ui/Button';
import { EditInvestmentPageReduxState } from './editInvestmentPageTypes';
import { editInvestmentActions } from './editInvestmentPageSlice';
import classes from './editInvestmentPage.module.scss';
import validateEditInvestmentForm from './editInvestmentPageValidation';
import { exchangeNetworkValues } from '../shared/exchangeNetwork';
import { ReferralPayoutMode, referralPayoutModeLookup } from '../shared/referralPayoutMode';
import { referralDjModeLookup } from '../shared/referralDjMode';

const EditInvestmentPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: EditInvestmentPageReduxState) => s.editInvestment.pageLoading,
  );
  const formLoading = useSelector(
    (s: EditInvestmentPageReduxState) => s.editInvestment.formLoading,
  );
  const form = useSelector(
    (s: EditInvestmentPageReduxState) => s.editInvestment.form,
  );
  const customers = useSelector(
    (s: EditInvestmentPageReduxState) => s.editInvestment.customers,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const parsedId = Number(params.id);
    dispatch(editInvestmentActions.init({ id: parsedId }));
  }, []);

  const onSubmit = React.useCallback((f) => {
    dispatch(editInvestmentActions.submitForm(f));
  }, [dispatch]);

  return (
    <ContentContainer>
      <PageTitle
        onBack={() => history.goBack()}
      >
        {form.formNo || 'Edit Investment'}
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <FormikForm
          initialValues={form}
          enableReinitialize
          validate={validateEditInvestmentForm}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <LoadingAlert loading={formLoading} />
              <Card title="Customer">
                <FormikDropDownList
                  name={nameof(values.customerId)}
                  label="Select Customer"
                  values={customers}
                />
              </Card>
              <div className="my-3" />
              <Card title="Investment">
                <div className="row">
                  <div className="col-12">
                    <FormValue
                      label="Form No."
                      value={values.formNo}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="Transaction Date & Time."
                      value={formatDateTime(values.transactionDateTime)}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="Maturity Date"
                      value={formatDate(values.maturityDate)}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="Amount"
                      value={`USDT ${formatCurrency(values.amount, 'two')}`}
                    />
                  </div>
                  <div className="col-md-6" />
                  <div className="col-12 col-md-6">
                    <FormikNumberField
                      id={nameof(values.bonusPayoutRate)}
                      name={nameof(values.bonusPayoutRate)}
                      label="Bonus Payout Rate"
                      inputPostLabel="%"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikDropDownList
                      id={nameof(values.exchangeNetwork)}
                      name={nameof(values.exchangeNetwork)}
                      label="Exchange Network"
                      values={exchangeNetworkValues}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikTextField
                      id={nameof(values.walletAddress)}
                      name={nameof(values.walletAddress)}
                      label="Wallet Address"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikTextField
                      id={nameof(values.transactionHash)}
                      name={nameof(values.transactionHash)}
                      label="Transaction Hash"
                    />
                  </div>
                </div>
              </Card>
              <div className="my-3" />
              <Card title="Referral">
                <div className="row">
                  <div className="col-12">
                    <FormikTextField
                      id={nameof(values.referralName)}
                      name={nameof(values.referralName)}
                      label="Referral Name"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="Referral Payout Mode"
                      value={referralPayoutModeLookup[values.referralPayoutMode]?.label}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="Referral DJ Mode"
                      value={referralDjModeLookup[values.referralDjMode || '']?.label}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="Referral Fee"
                      value={`USDT ${formatCurrency(values.referralFee, 'two')}`}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormValue
                      label="DJ Referral Fee"
                      value={`USDT ${formatCurrency(values.referralDjFee, 'two')}`}
                    />
                  </div>
                  {values.referralPayoutMode === ReferralPayoutMode.Instant && (
                    <>
                      <div className="col-12 col-md-6">
                        <FormikDatePicker
                          id={nameof(values.referralTransactionDateTime)}
                          name={nameof(values.referralTransactionDateTime)}
                          label="Transaction Date & Time"
                          time
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikDropDownList
                          id={nameof(values.referralExchangeNetwork)}
                          name={nameof(values.referralExchangeNetwork)}
                          label="Exchange Network"
                          values={exchangeNetworkValues}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.referralWalletAddress)}
                          name={nameof(values.referralWalletAddress)}
                          label="Wallet Address"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.referralTransactionHash)}
                          name={nameof(values.referralTransactionHash)}
                          label="Transaction Hash"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>
              <FooterActionContainer>
                <Button type="submit" label="Save" isLoading={formLoading.isLoading} />
                <div className="mx-2" />
                <Button type="button" label="Cancel" secondary onClick={() => history.goBack()} />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default EditInvestmentPage;
