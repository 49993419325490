import * as React from 'react';
import classes from './cardSummaryBlock.module.scss';

interface CardSummaryBlockProps {
  children: React.ReactNode,
  title: string,
}

const CardSummaryBlock: React.FC<CardSummaryBlockProps> = ({ title, children }) => (
  <div className="card mb-3 mb-md-0">
    <div className="card-body">
      {title && <div className={`card-title ${classes['card-title']}`}>{title}</div>}
      <div className={classes['card-content']}>
        {children}
      </div>
    </div>
  </div>
);

export default CardSummaryBlock;
