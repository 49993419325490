import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { defaultList, List, PageScrollDirection } from 'common/util/list';
import {
  SelectSpotSellInvestmentModalState, SelectSpotSellInvestmentModalInit,
  SelectSpotSellInvestmentModalCallback, TradeRemainingQuantity, TradeRemainingQuantityToSell,
} from './selectSpotSellInvestmentModalTypes';

const initialState: SelectSpotSellInvestmentModalState = {
  isOpen: false,
  pageLoading: initialLoadingTrueState,
  tradeId: 0,
  selected: {},
  list: defaultList,
  callbackFunc: emptyFunction,
};

const selectSpotSellInvestmentSlice = createSlice({
  name: 'selectSpotSellInvestment',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<SelectSpotSellInvestmentModalInit>) => state,
    commit: (state, _action: PayloadAction<boolean>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setIsOpen(state, { payload }: PayloadAction<boolean>) {
      state.isOpen = payload;
      return state;
    },
    setTradeId(state, { payload }: PayloadAction<number>) {
      state.tradeId = payload;
      return state;
    },
    setList(state, { payload }: PayloadAction<List<TradeRemainingQuantity>>) {
      state.list = payload;
      return state;
    },
    setSelected(state, { payload }: PayloadAction<Record<number, TradeRemainingQuantityToSell>>) {
      state.selected = payload;
      return state;
    },
    page: (state, _action: PayloadAction<PageScrollDirection>) => state,
    setCallbackFunc(state, { payload }: PayloadAction<SelectSpotSellInvestmentModalCallback>) {
      state.callbackFunc = payload;
      return state;
    },
  },
});

export const selectSpotSellInvestmentActions = selectSpotSellInvestmentSlice.actions;
export const selectSpotSellInvestmentReducer = selectSpotSellInvestmentSlice.reducer;
