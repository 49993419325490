import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum ExchangeNetwork {
  ERC20 = 'erc20',
  TRC20 = 'trc20',
  BEP2 = 'bep2',
  BEPBSC = 'bepbsc',
  OMNI = 'omni',
}

export const exchangeNetworkValues: ValueLabelLookup[] = [
  { label: nameof(ExchangeNetwork.ERC20), value: ExchangeNetwork.ERC20 },
  { label: nameof(ExchangeNetwork.TRC20), value: ExchangeNetwork.TRC20 },
  { label: nameof(ExchangeNetwork.BEP2), value: ExchangeNetwork.BEP2 },
  { label: nameof(ExchangeNetwork.BEPBSC), value: ExchangeNetwork.BEPBSC },
  { label: nameof(ExchangeNetwork.OMNI), value: ExchangeNetwork.OMNI },
];

export const exchangeNetworkLookup = keyBy(exchangeNetworkValues, 'value');
