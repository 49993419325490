import { ISagaModule } from 'redux-dynamic-modules-saga';
import editInvestmentSaga from './editInvestmentPageSaga';
import { editInvestmentReducer } from './editInvestmentPageSlice';
import { EditInvestmentPageReduxState } from './editInvestmentPageTypes';

export default (): ISagaModule<EditInvestmentPageReduxState> => ({
  id: 'editInvestment',
  reducerMap: {
    editInvestment: editInvestmentReducer,
  },
  sagas: [editInvestmentSaga],
  retained: true,
});
