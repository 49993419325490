import { fromApiDate } from 'common/util/date';
import { FutureDetail, FutureDetailRemote, FutureDetailSettlement } from './futureDetailPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapFutureTransactionsFromApi = (response: FutureDetailRemote): FutureDetail => {
  const { future_fund_settlements: settlements } = response;

  const mapped = settlements.map<FutureDetailSettlement>((b) => ({
    id: b.id,
    appliedAmount: b.future_current_amount,
    dateTime: fromApiDate(b.date_time),
    amount: b.settlement_amount,
    percent: b.settlement_percent,
    totalPax: b.total_pax,
  }))
    .sort((a, b) => a.dateTime.diff(b.dateTime).valueOf());

  return {
    fundPool: response.fund_pool,
    totalActiveInvestors: response.total_active_investor,
    settlements: mapped,
  };
};
