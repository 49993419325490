import Big from 'big.js';
import { isUseV2Calculation } from 'common/setup/config';
import { ReferralDjMode } from './referralDjMode';

const setReferralFee = (form: { referralFee: unknown, referralDjFee: unknown },
  amount: number|null,
  mode: ReferralDjMode|undefined,
  setFieldValue: (field: string, value: number|null) => void): void => {
  let referralFee: number|null = null;
  let djReferralFee: number|null = null;
  // 5% for v2, 4.16% for v1.
  const commisionRate = isUseV2Calculation()
    ? 0.05 : 0.0416;

  if (amount != null) {
    const totalReferralFee = new Big(amount)
      .times(commisionRate);

    let referralRate = 1;
    let djRate = 0;
    if (mode === ReferralDjMode.SixFour) {
      djRate = 0.6;
      referralRate = 0.4;
    } if (mode === ReferralDjMode.FiveFive) {
      djRate = 0.5;
      referralRate = 0.5;
    }

    referralFee = totalReferralFee
      .mul(referralRate)
      .round(2, 0)
      .toNumber();

    djReferralFee = totalReferralFee
      .mul(djRate)
      .round(2, 0)
      .toNumber();
  }

  setFieldValue(nameof(form.referralFee), referralFee);
  setFieldValue(nameof(form.referralDjFee), djReferralFee);
};

export default setReferralFee;
