import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Big } from 'big.js';

import LoadingSwitch from 'common/ui/LoadingSwitch';
import PageTitle from 'common/ui/PageTitle';

import {
  FormikDatePicker, FormikDropDownList, FormikForm, FormikNumberField, FormikTextField,
} from 'common/form';
import Card from 'common/ui/Card';
import ContentContainer from 'common/ui/ContentContainer';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button, { LinkButton } from 'common/ui/Button';
import { LoadingAlert } from 'common/ui/Alert';
import appConfig, { isUseV2Calculation } from 'common/setup/config';
import classes from './createInvestmentPage.module.scss';
import { createInvestmentActions } from './createInvestmentPageSlice';
import { CreateInvestmentForm, CreateInvestmentPageQueryString, CreateInvestmentPageReduxState } from './createInvestmentPageTypes';
import { ExchangeNetwork, exchangeNetworkValues } from '../shared/exchangeNetwork';
import { ReferralPayoutMode, referralPayoutModeValues } from '../shared/referralPayoutMode';
import validateCreateInvestmentForm from './createInvestmentPageValidation';
import { ReferralDjMode, referralDjModeLookup, referralDjModeValues } from '../shared/referralDjMode';
import setReferralFee from '../shared/setReferralFee';

const getBonusPercentage = (amount: number|null) => {
  if (appConfig.appVersion >= 2) {
    return 0;
  }

  if (!amount) {
    return 0;
  } if (amount >= 2500000) {
    return 25;
  } if (amount >= 1000000) {
    return 15;
  } if (amount >= 500000) {
    return 10;
  } if (amount >= 100000) {
    return 8;
  } if (amount >= 50000) {
    return 5;
  }

  return 0;
};

const CreateInvestmentPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.pageLoading,
  );
  const formLoading = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.formLoading,
  );
  const form = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.form,
  );
  const customers = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.customers,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const qs = queryString.parse(window.location.search);
    const initParam: CreateInvestmentPageQueryString = {
      createdCustomer: typeof qs.createdCustomer === 'string' ? Number(qs.createdCustomer) : undefined,
    };

    dispatch(createInvestmentActions.init(initParam));
  }, []);

  const onSubmit = React.useCallback((f) => {
    dispatch(createInvestmentActions.submit(f));
  }, [dispatch]);

  return (
    <ContentContainer>
      <PageTitle>Add Investment</PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <FormikForm
          initialValues={form}
          validate={validateCreateInvestmentForm}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LoadingAlert loading={formLoading} />
              <Card title="Customer">
                <FormikDropDownList
                  name={nameof(values.customerId)}
                  label="Select Customer"
                  values={customers}
                />
                <LinkButton
                  label="Create New Customer"
                  onClick={() => {
                    dispatch(createInvestmentActions.createNewCustomer(values));
                  }}
                />
              </Card>
              <div className="my-3" />
              <Card title="Investment">
                <div className="row">
                  <div className="col-12">
                    <FormikTextField
                      id={nameof(values.formNo)}
                      name={nameof(values.formNo)}
                      label="Form No."
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikDatePicker
                      id={nameof(values.transactionDateTime)}
                      name={nameof(values.transactionDateTime)}
                      label="Transaction Date & Time"
                      time
                      onChange={(date) => {
                        if (date) {
                          const plusYear = isUseV2Calculation() ? 3 : 1;
                          setFieldValue(
                            nameof(values.maturityDate),
                            date.plus({ years: plusYear }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikDatePicker
                      id={nameof(values.maturityDate)}
                      name={nameof(values.maturityDate)}
                      label="Maturity Date"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikNumberField
                      id={nameof(values.amount)}
                      name={nameof(values.amount)}
                      label="Amount"
                      inputPreLabel="USDT"
                      decimal={2}
                      onBlur={(value) => {
                        if (value) {
                          setFieldValue(nameof(form.bonusPayoutRate), getBonusPercentage(value));
                          setReferralFee(values, value, values.referralDjMode, setFieldValue);
                        } else {
                          setFieldValue(nameof(form.bonusPayoutRate), 0);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikNumberField
                      id={nameof(values.bonusPayoutRate)}
                      name={nameof(values.bonusPayoutRate)}
                      label="Bonus Payout Rate"
                      inputPostLabel="%"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikDropDownList
                      id={nameof(values.exchangeNetwork)}
                      name={nameof(values.exchangeNetwork)}
                      label="Exchange Network"
                      values={exchangeNetworkValues}
                    />
                  </div>
                  <div className="col-md-6" />
                  <div className="col-12 col-md-6">
                    <FormikTextField
                      id={nameof(values.walletAddress)}
                      name={nameof(values.walletAddress)}
                      label="Wallet Address"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikTextField
                      id={nameof(values.transactionHash)}
                      name={nameof(values.transactionHash)}
                      label="Transaction Hash"
                    />
                  </div>
                </div>
              </Card>
              <div className="my-3" />
              <Card title="Referral">
                <div className="row">
                  <div className="col-12">
                    <FormikTextField
                      id={nameof(values.referralName)}
                      name={nameof(values.referralName)}
                      label="Referral Name"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikDropDownList
                      id={nameof(values.referralPayoutMode)}
                      name={nameof(values.referralPayoutMode)}
                      label="Referral Payout Mode"
                      values={referralPayoutModeValues}
                      onChange={(value) => {
                        if (value === ReferralPayoutMode.Instant) {
                          setFieldValue(nameof(values.referralTransactionDateTime), null);
                          setFieldValue(nameof(values.referralExchangeNetwork),
                            ExchangeNetwork.ERC20);
                          setFieldValue(nameof(values.referralWalletAddress), '');
                          setFieldValue(nameof(values.referralTransactionHash), '');
                        } else {
                          setFieldValue(nameof(values.referralTransactionDateTime), null);
                          setFieldValue(nameof(values.referralExchangeNetwork),
                            ExchangeNetwork.ERC20);
                          setFieldValue(nameof(values.referralWalletAddress), '');
                          setFieldValue(nameof(values.referralTransactionHash), '');
                        }
                        setReferralFee(values, values.amount, values.referralDjMode, setFieldValue);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikDropDownList
                      id={nameof(values.referralDjMode)}
                      name={nameof(values.referralDjMode)}
                      label="Referral DJ Mode"
                      values={referralDjModeValues}
                      onChange={(value) => {
                        if (value) {
                          const mode = referralDjModeLookup[value];
                          setReferralFee(values, values.amount, mode.value, setFieldValue);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikNumberField
                      id={nameof(values.referralFee)}
                      name={nameof(values.referralFee)}
                      label="Referral Fee"
                      inputPreLabel="USDT"
                      decimal={2}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikNumberField
                      id={nameof(values.referralDjFee)}
                      name={nameof(values.referralDjFee)}
                      label="DJ Referral Fee"
                      inputPreLabel="USDT"
                      decimal={2}
                    />
                  </div>
                  {values.referralPayoutMode === ReferralPayoutMode.Instant && (
                    <>
                      <div className="col-12 col-md-6">
                        <FormikDatePicker
                          id={nameof(values.referralTransactionDateTime)}
                          name={nameof(values.referralTransactionDateTime)}
                          label="Transaction Date & Time"
                          time
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikDropDownList
                          id={nameof(values.referralExchangeNetwork)}
                          name={nameof(values.referralExchangeNetwork)}
                          label="Exchange Network"
                          values={exchangeNetworkValues}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.referralWalletAddress)}
                          name={nameof(values.referralWalletAddress)}
                          label="Wallet Address"
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <FormikTextField
                          id={nameof(values.referralTransactionHash)}
                          name={nameof(values.referralTransactionHash)}
                          label="Transaction Hash"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>
              <FooterActionContainer>
                <Button type="submit" label="Save" isLoading={pageLoading.isLoading} />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CreateInvestmentPage;
