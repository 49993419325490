/**
 * Prompt user to download a copy of the specified binary content.
 * @param {blob} blob The binary content to serve.
 * @param {string} fileName File name to save as.
 */
// eslint-disable-next-line import/prefer-default-export
export const downloadBinary = (blob: Blob, fileName: string): void => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
