import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call, select } from 'typed-redux-saga';
import ApiService from 'common/api';
import { Action } from 'redux';
import toastActions from 'common/ui/Toast/toastActions';
import { toApiDateTime } from 'common/util/date';
import { getTradePrices } from 'context/trade/tradeSaga';
import { spotDetailActions } from './spotDetailPageSlice';
import { SpotDetailPageReduxState, SpotDetailRemote } from './spotDetailPageTypes';
import { mapSpotDetailFromApi } from './spotDetailPageMapper';
import { SpotSellFor } from './spotSellFor';

function* loadDetail(id: number): Generator {
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(spotDetailActions.setPageLoading);
  yield put(loadingStart());
  try {
    const resp = yield* call(ApiService.get<SpotDetailRemote>(), `/v1/trades/${id}`);
    const prices = yield* call(getTradePrices, [resp.coingecko_id]);
    const detail = mapSpotDetailFromApi(resp, prices[resp.coingecko_id]);

    yield put(spotDetailActions.setDetail(detail));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onInit(action: Action): Generator {
  if (!spotDetailActions.init.match(action)) { return; }
  yield put(spotDetailActions.setInitialState());
  yield* call(loadDetail, action.payload.id);
}

function* onSubmit(action: Action): Generator {
  if (!spotDetailActions.submit.match(action)) { return; }

  const form = action.payload;
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(spotDetailActions.setFormLoading);
  yield put(loadingStart());

  try {
    const tradeId = yield* select((s: SpotDetailPageReduxState) => s.spotDetail.detail.id);

    if (form.sellFor === SpotSellFor.All) {
      yield* call(ApiService.post(), '/v1/spot-trades/sell', {
        date_time: toApiDateTime(form.dateTime),
        unit_price: form.unitPrice,
        quantity: form.quantity,
        total: form.total,
        transaction_fee: form.transactionFee || 0,
        trade_id: tradeId,
      });
    } else {
      yield* call(ApiService.post(), '/v1/spot-trades/sell_specific', {
        date_time: toApiDateTime(form.dateTime),
        unit_price: form.unitPrice,
        targets: form.sellForTargets.isConfirm && form.sellForTargets.selected.map((s) => ({
          spot_fund_id: s.spotFundId,
          quantity: s.sellQuantity,
        })),
        total: form.total,
        transaction_fee: form.transactionFee || 0,
        trade_id: tradeId,
      });
    }
    toastActions.success('Sell trade order successfully added.');
    yield put(loadingSuccess());
    yield* call(loadDetail, tradeId);
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(spotDetailActions.init.type, onInit);
  yield takeLatest(spotDetailActions.submit.type, onSubmit);
}
