import { DateTime } from 'luxon';
import { CheckResult, ValidatorParam } from './validatorTypes';

const required = (param?: ValidatorParam) => (value: DateTime|null): CheckResult => {
  const { message = 'Cannot be blank' } = param || {};
  return {
    isValid: !!value,
    message,
  };
};

export default {
  required,
};
