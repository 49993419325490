import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { ModalPromptButton, ModalPromptMessage } from 'common/ui/Modal';
import { InvestmentListPageReduxState } from './investmentListPageTypes';
import { investmentListActions } from './investmentListPageSlice';

const DeleteInvestmentModal: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector((s: InvestmentListPageReduxState) => s.investmentList.deleteLoading);
  const prompt = useSelector((s: InvestmentListPageReduxState) => s.investmentList.deletePrompt);

  React.useEffect(() => {
    if (loading.isSuccess) {
      dispatch(investmentListActions.setDeletePrompt());
    }
  }, [loading]);

  return (
    <Modal
      size="small"
      isOpen={prompt.id > 0}
    >
      <ModalPromptMessage>
        {`Are you sure you want to delete the investment '${prompt.name}'?`}
      </ModalPromptMessage>
      <ModalPromptButton
        isLoading={loading.isLoading}
        yesLabel="Confirm"
        noLabel="Cancel"
        onYesClick={() => dispatch(investmentListActions.confirmDelete())}
        onNoClick={() => dispatch(investmentListActions.setDeletePrompt())}
      />
    </Modal>
  );
};

export default DeleteInvestmentModal;
