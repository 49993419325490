import { toApiDateTime } from 'common/util/date';
import { CreateTradeForm } from './createTradePageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapCreateTradeToApi = (form: CreateTradeForm)
  : unknown => ({
  id: form.id,
  trade_id: form.tradeId,
  date_time: toApiDateTime(form.dateTime),
  unit_price: form.buyUnitPrice,
  quantity: form.buyQuantity,
  total: form.buyTotal,
});
