import clsx from 'clsx';
import * as React from 'react';

export { default as LinkButton } from './LinkButton';
export { default as IconButton } from './IconButton';

interface ButtonProps {
  type?: 'button' | 'submit'
  label: string,
  isLoading?: boolean,
  className?: string,
  secondary?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

const Button: React.FC<ButtonProps> = ({
  type, className, isLoading, label, onClick, secondary,
}) => (
  <button
    type={type === 'button' ? 'button' : 'submit'}
    className={clsx('btn', secondary ? 'btn-light' : 'btn-dark', className)}
    onClick={onClick}
    disabled={isLoading}
  >
    {label}
  </button>
);

export default Button;
