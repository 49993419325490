import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'common/util/currency';
import classes from './spotListPage.module.scss';
import { spotListActions } from './spotListPageSlice';
import { SpotListPageReduxState } from './spotListPageTypes';

const SpotListPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SpotListPageReduxState) => s.spotList.pageLoading,
  );
  const list = useSelector(
    (s: SpotListPageReduxState) => s.spotList.list,
  );
  const spotBalance = useSelector(
    (s: SpotListPageReduxState) => s.spotList.spotBalance,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(spotListActions.init());
  }, []);

  return (
    <ContentContainer>
      <PageTitle>Trade List</PageTitle>
      <Card>
        <LoadingSwitch loading={pageLoading}>
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Symbol</th>
                <th scope="col">Name</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  USDT
                </th>
                <td>Spot Account Balance</td>
                <td>{formatCurrency(spotBalance, 'two')}</td>
              </tr>
              {list.map((i) => (
                <tr key={i.id}>
                  <th scope="row">
                    <Link to={`/member/spot-trade/detail/${i.id}`}>
                      {i.symbol}
                    </Link>
                  </th>
                  <td>{i.name}</td>
                  <td>{formatCurrency(i.balance, 'six')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingSwitch>
      </Card>
    </ContentContainer>
  );
};

export default SpotListPage;
