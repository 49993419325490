import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum InvestmentCloseType {
  Withdraw = 'withdraw',
  Reinvest = 'reinvest',
}

export const investmentCloseTypeValues: ValueLabelLookup[] = [
  { label: nameof(InvestmentCloseType.Withdraw), value: InvestmentCloseType.Withdraw },
  { label: nameof(InvestmentCloseType.Reinvest), value: InvestmentCloseType.Reinvest },
];

export const investmentCloseTypeLookup = keyBy(investmentCloseTypeValues, 'value');
