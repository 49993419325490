import { ISagaModule } from 'redux-dynamic-modules-saga';
import closeInvestmentSaga from './closeInvestmentPageSaga';
import { closeInvestmentReducer } from './closeInvestmentPageSlice';
import { CloseInvestmentPageReduxState } from './closeInvestmentPageTypes';

export default (): ISagaModule<CloseInvestmentPageReduxState> => ({
  id: 'closeInvestment',
  reducerMap: {
    closeInvestment: closeInvestmentReducer,
  },
  sagas: [closeInvestmentSaga],
  retained: true,
});
