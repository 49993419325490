import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import {
  FormikDatePicker, FormikDropDownList, FormikForm, FormikNumberField,
} from 'common/form';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button from 'common/ui/Button';
import { LoadingAlert } from 'common/ui/Alert';
import Big from 'big.js';
import classes from './createTradePage.module.scss';
import { createTradeActions } from './createTradePageSlice';
import { CreateTradePageReduxState } from './createTradePageTypes';
import validateCreateTradeForm from './createTradePageValidation';

const CreateTradePage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CreateTradePageReduxState) => s.createTrade.pageLoading,
  );
  const formLoading = useSelector(
    (s: CreateTradePageReduxState) => s.createTrade.formLoading,
  );
  const form = useSelector(
    (s: CreateTradePageReduxState) => s.createTrade.form,
  );
  const trades = useSelector(
    (s: CreateTradePageReduxState) => s.createTrade.trades,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(createTradeActions.init());
  }, []);

  const onSubmit = React.useCallback((f) => {
    dispatch(createTradeActions.submitForm(f));
  }, [dispatch]);

  return (
    <ContentContainer>
      <PageTitle>Create Spot Trade</PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <FormikForm
          initialValues={form}
          validate={validateCreateTradeForm}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <LoadingAlert loading={formLoading} />
              <Card title="Details">
                <div className="row">
                  <div className="col-12">
                    <FormikDatePicker
                      id={nameof(values.dateTime)}
                      name={nameof(values.dateTime)}
                      label="Transaction Date Time"
                      time
                    />
                  </div>
                  <div className="col-12">
                    <FormikDropDownList
                      id={nameof(values.tradeId)}
                      name={nameof(values.tradeId)}
                      label="Trade"
                      values={trades}
                    />
                  </div>
                  <div className="col-12">
                    <FormikNumberField
                      id={nameof(values.buyUnitPrice)}
                      name={nameof(values.buyUnitPrice)}
                      label="Buy Unit Price"
                      inputPreLabel="USDT"
                      decimal={3}
                    />
                  </div>
                  <div className="col-12">
                    <FormikNumberField
                      id={nameof(values.buyQuantity)}
                      name={nameof(values.buyQuantity)}
                      label="Buy Quantity"
                      decimal={6}
                      onBlur={(value) => {
                        if (value) {
                          const total = new Big(value)
                            .times(values.buyUnitPrice || 0)
                            .round(2)
                            .toNumber();
                          setFieldValue(nameof(values.buyTotal), total);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <FormikNumberField
                      id={nameof(values.buyTotal)}
                      name={nameof(values.buyTotal)}
                      label="Buy Total"
                      inputPreLabel="USDT"
                      decimal={2}
                    />
                  </div>
                </div>
              </Card>
              <FooterActionContainer>
                <Button type="submit" label="Submit" isLoading={formLoading.isLoading} />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CreateTradePage;
