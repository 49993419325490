import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call, SagaGenerator, select } from 'typed-redux-saga';
import ApiService from 'common/api';
import { Action } from 'redux';
import { toApiDate, toApiDateTime } from 'common/util/date';
import toastActions from 'common/ui/Toast/toastActions';
import { DateTime } from 'luxon';
import { spotQuantityByMaturityActions } from './spotQuantityByMaturityPageSlice';
import {
  SpotQuantityByMaturityForm, SpotQuantityByMaturityPageReduxState, SpotQuantityByMaturityRemote,
  SpotQuantityByMaturityResult,
} from './spotQuantityByMaturityPageTypes';
import { fromSpotQuantityByMaturityRemote } from './spotQuantityByMaturityPageMapper';

function* loadResult(form: SpotQuantityByMaturityForm)
: SagaGenerator<SpotQuantityByMaturityResult> {
  const response = yield* call(
    ApiService.get<SpotQuantityByMaturityRemote>(),
    `/v1/spot-trades/remaining-by-maturity?maturity_date=${toApiDate(form.maturityDate)}`,
  );

  const result = fromSpotQuantityByMaturityRemote(response, form);

  return result;
}

function* onInit(action: Action): Generator {
  if (!spotQuantityByMaturityActions.init.match(action)) { return; }

  const [,
    loadingFail, loadingSuccess,
  ] = bindLoadingActions(spotQuantityByMaturityActions.setPageLoading);

  try {
    const result = yield* call(loadResult, action.payload);

    yield put(spotQuantityByMaturityActions.setResult(result));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!spotQuantityByMaturityActions.submit.match(action)) { return; }

  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(spotQuantityByMaturityActions.setPageLoading);

  yield put(loadingStart());

  try {
    const result = yield* call(loadResult, action.payload);

    yield put(spotQuantityByMaturityActions.setResult(result));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmitSellAll(action: Action): Generator {
  if (!spotQuantityByMaturityActions.submitSellAll.match(action)) { return; }
  const form = action.payload;

  const { sellAllPrompt, result } = yield* select((s: SpotQuantityByMaturityPageReduxState) => s
    .spotQuantityByMaturity);

  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(spotQuantityByMaturityActions.setSellAllLoading);

  yield put(loadingStart());

  try {
    yield* call(ApiService.post(), '/v1/spot-trades/sell_specific', {
      trade_id: sellAllPrompt.tradeId,
      date_time: toApiDateTime(form.dateTime),
      unit_price: form.unitPrice,
      targets: sellAllPrompt.spotFunds.map((s) => ({
        spot_fund_id: s.id,
        quantity: s.remainingQuantity,
      })),
      total: form.total,
      transaction_fee: form.transactionFee,
    });

    toastActions.success('Successfully sold all trade to the included investments.');
    yield put(loadingSuccess());
    yield put(spotQuantityByMaturityActions.setSellAllPrompt());

    const newResult = yield* call(loadResult, {
      maturityDate: result.date || DateTime.local(),
    });
    yield put(spotQuantityByMaturityActions.setResult(newResult));
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(spotQuantityByMaturityActions.init.type, onInit);
  yield takeLatest(spotQuantityByMaturityActions.submit.type, onSubmit);
  yield takeLatest(spotQuantityByMaturityActions.submitSellAll.type, onSubmitSellAll);
}
