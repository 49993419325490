import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { ModalPromptButton } from 'common/ui/Modal';
import { FormikDatePicker, FormikForm, FormikNumberField } from 'common/form';
import FormValue from 'common/ui/FormValue';
import { formatCurrency } from 'common/util/currency';
import {
  dateValidator, numberValidator, stringValidator, Validate,
} from 'common/form/validations';
import Big from 'big.js';
import { LoadingAlert } from 'common/ui/Alert';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState, InvestmentPartialPayoutForm } from './investmentDetailPageTypes';

const initialForm: InvestmentPartialPayoutForm = {
  id: 0,
  amount: null,
  percent: null,
  transactionDateTime: null,
};

const validateForm: Validate<InvestmentPartialPayoutForm> = ({ form, validator }) => {
  validator.validateField(nameof(form.amount), stringValidator.required(), numberValidator.gt(0))
    .validateField(nameof(form.percent), stringValidator.required(), numberValidator.gt(0))
    .validateField(nameof(form.transactionDateTime), dateValidator.required());

  return validator;
};

const PartialPayoutModal: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPaymentLoading,
  );
  const prompt = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialPaymentPrompt,
  );
  const [promptForm, setPromptForm] = React.useState(initialForm);

  React.useEffect(() => {
    if (loading.isSuccess) {
      dispatch(investmentDetailActions.setPartialPaymentPrompt());
    }
  }, [loading]);

  const calculatePercent = (amount: number) => new Big(amount)
    .div(prompt.amount)
    .mul(100)
    .round(2)
    .toNumber();

  const calculateAmount = (percent: number) => new Big(prompt.amount)
    .mul(percent)
    .div(100)
    .round(2)
    .toNumber();

  React.useEffect(() => {
    if (prompt.id) {
      const defaultPercent = 10;
      setPromptForm({
        id: prompt.id,
        amount: calculateAmount(defaultPercent),
        percent: defaultPercent,
        transactionDateTime: null,
      });
    }
  }, [prompt]);

  return (
    <Modal
      size="small"
      isOpen={prompt.id > 0}
    >
      <h3>Perform Partial Payout</h3>
      <FormikForm
        initialValues={promptForm}
        enableReinitialize
        validate={validateForm}
        onSubmit={(f) => {
          dispatch(investmentDetailActions.submitPartialPayment(f));
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <LoadingAlert loading={loading} />
            <FormValue
              label="Form No:"
              value={prompt.name}
            />
            <FormValue
              label="Investment Amount:"
              value={`${formatCurrency(prompt.amount)} USDT`}
            />
            <FormikDatePicker
              time
              name={nameof(values.transactionDateTime)}
              label="Transaction Date Time"
            />
            <FormikNumberField
              name={nameof(values.percent)}
              inputPostLabel="%"
              label="Payout Percent"
              decimal={2}
              onBlur={(newValue) => {
                if (newValue) {
                  setFieldValue(nameof(values.amount), calculateAmount(newValue));
                }
              }}
            />
            <FormikNumberField
              name={nameof(values.amount)}
              inputPostLabel="USDT"
              label="Payout Amount"
              onBlur={(newValue) => {
                if (newValue) {
                  setFieldValue(nameof(values.percent), calculatePercent(newValue));
                }
              }}
            />
            <ModalPromptButton
              isLoading={loading.isLoading}
              yesLabel="Confirm"
              noLabel="Cancel"
              onYesClick={handleSubmit}
              onNoClick={() => dispatch(investmentDetailActions.setPartialPaymentPrompt())}
            />
          </form>
        )}
      </FormikForm>
    </Modal>
  );
};

export default PartialPayoutModal;
