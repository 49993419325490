import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TradePrice {
  lastUpdated: number,
  price: number,
}

export interface TradeContextState {
  tradePriceLookup: Record<string, TradePrice>,
}

const initialState: TradeContextState = {
  tradePriceLookup: {},
};

const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    setTradePriceLookup(state, { payload }: PayloadAction<Record<string, TradePrice>>) {
      state.tradePriceLookup = payload;
    },
  },
});

export const tradeActions = tradeSlice.actions;
export const tradeReducer = tradeSlice.reducer;
