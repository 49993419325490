import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum ReferralDjMode {
  None = 'none',
  SixFour = 'six_four',
  FiveFive = 'five_five',
}

export const referralDjModeValues: ValueLabelLookup<ReferralDjMode>[] = [
  { label: 'None', value: ReferralDjMode.None },
  { label: 'DJ 6:4 Marketer', value: ReferralDjMode.SixFour },
  { label: 'DJ 5:5 Marketer', value: ReferralDjMode.FiveFive },
];

export const referralDjModeLookup = keyBy(referralDjModeValues, 'value');
