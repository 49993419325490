import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api';
import { getTradePrices } from 'context/trade/tradeSaga';
import { spotSummaryReportActions } from './spotSummaryReportPageSlice';
import { SpotSummaryReport, SpotSummaryReportRemote } from './spotSummaryReportPageTypes';

function* onInit(): Generator {
  yield put(spotSummaryReportActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    spotSummaryReportActions.setPageLoading,
  );
  try {
    const remote = yield* call(ApiService.get<SpotSummaryReportRemote>(), '/v1/spot-trades/summary');

    const tradePrices = yield* call(getTradePrices, remote.all_trades.map((t) => t.coingecko_id));
    const totalHoldingCoin = remote.all_trades.reduce(
      (prev, t) => prev + (tradePrices[t.coingecko_id].price * t.balance),
      0,
    );

    const result: SpotSummaryReport = {
      totalCompanyFund: remote.total_company_fund,
      totalInvestorFund: remote.total_investor_fund,
      totalHoldingFund: totalHoldingCoin,
      totalCompanyAndHoldingFund: totalHoldingCoin + remote.total_company_fund,
      totalProfitLossPercent: ((remote.total_company_fund / remote.total_investor_fund) * 100)
        - 100,
      totalProfitLossInclHoldingPercent: ((
        (remote.total_company_fund + totalHoldingCoin)
        / remote.total_investor_fund) * 100) - 100,
    };

    yield put(spotSummaryReportActions.setReport(result));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(spotSummaryReportActions.init.type, onInit);
}
