import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { DateTime } from 'luxon';
import { IdLabelLookup } from 'common/util/lookup';
import { EditInvestmentForm, EditInvestmentPageParam, EditInvestmentPageState } from './editInvestmentPageTypes';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { ReferralDjMode } from '../shared/referralDjMode';

export const initialInvestmentForm: EditInvestmentForm = {
  id: 0,
  customerId: null,
  formNo: '',
  transactionDateTime: DateTime.local(),
  maturityDate: DateTime.local(),
  amount: 0,
  bonusPayoutRate: 0,
  exchangeNetwork: ExchangeNetwork.ERC20,
  walletAddress: '',
  transactionHash: '',
  referralName: '',
  referralPayoutMode: ReferralPayoutMode.Yearly,
  referralDjMode: ReferralDjMode.None,
  referralFee: 0,
  referralDjFee: 0,
  referralTransactionDateTime: null,
  referralExchangeNetwork: ExchangeNetwork.ERC20,
  referralWalletAddress: '',
  referralTransactionHash: '',
};

const initialState: EditInvestmentPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  form: initialInvestmentForm,
  customers: [],
};

const editInvestmentSlice = createSlice({
  name: 'editInvestment',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<EditInvestmentPageParam>) => state,
    submitForm: (state, _action: PayloadAction<EditInvestmentForm>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<EditInvestmentForm>) {
      state.form = payload;
      return state;
    },
    setCustomers(state, { payload }: PayloadAction<IdLabelLookup[]>) {
      state.customers = payload;
      return state;
    },
  },
});

export const editInvestmentActions = editInvestmentSlice.actions;
export const editInvestmentReducer = editInvestmentSlice.reducer;
