import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import { Redirect } from 'react-router';
import { signInCallbackActions } from './signInCallbackPageSlice';
import { SignInCallbackPageQueryString, SignInCallbackPageReduxState } from './signInCallbackPageTypes';

const SignInCallbackPage: React.FC = () => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = React.useState<string>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SignInCallbackPageReduxState) => s.signInCallback.pageLoading,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const qs = queryString.parse(window.location.search);
    const initParam: SignInCallbackPageQueryString = {
      email: typeof qs.email === 'string' ? qs.email : '',
      accessToken: typeof qs.access_token === 'string' ? qs.access_token : '',
      refreshToken: typeof qs.refresh_token === 'string' ? qs.refresh_token : '',
      name: typeof qs.name === 'string' ? qs.name : '',
      s: typeof qs.s === 'string' ? qs.s : undefined,
    };
    dispatch(signInCallbackActions.init(initParam));

    return () => {
      dispatch(signInCallbackActions.setInitialState());
    };
  }, []);

  React.useEffect(() => {
    if (pageLoading.isLoading === false) {
      if (typeof pageLoading.data === 'string') {
        setRedirect(pageLoading.data);
      }
    }
  }, [pageLoading]);

  return redirect ? <Redirect to={redirect} /> : null;
};

export default SignInCallbackPage;
