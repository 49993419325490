import { ISagaModule } from 'redux-dynamic-modules-saga';
import selectSpotSellInvestmentSaga from './selectSpotSellInvestmentModalSaga';
import { selectSpotSellInvestmentReducer } from './selectSpotSellInvestmentModalSlice';
import { SelectSpotSellInvestmentModalReduxState } from './selectSpotSellInvestmentModalTypes';

export default (): ISagaModule<SelectSpotSellInvestmentModalReduxState> => ({
  id: 'selectSpotSellInvestment',
  reducerMap: {
    selectSpotSellInvestment: selectSpotSellInvestmentReducer,
  },
  sagas: [selectSpotSellInvestmentSaga],
  retained: true,
});
