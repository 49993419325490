import * as React from 'react';
import { connect, getIn } from 'formik';
import { ConnectedFormikProps } from './formikTypes';

interface FormikTextAreaFieldProps {
  name: string,
  rows?: number,
  id?: string,
  placeholder?: string,
  label?: string,
  disabled?: boolean,
  readOnly?: boolean,
}

const FormikTextAreaField: React.FC<FormikTextAreaFieldProps & ConnectedFormikProps> = ({
  formik,
  name,
  rows,
  id,
  placeholder,
  label,
  disabled,
  readOnly,
}) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const value = getIn(formik.values, name);
  const isInvalid = error && touch;

  return (
    <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
      { label && (
      <label htmlFor={id}>
        {label}
      </label>
      ) }
      <textarea
        name={name}
        rows={rows}
        className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
        id={id}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);
        }}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
      />
      {isInvalid && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default connect<FormikTextAreaFieldProps, unknown>(FormikTextAreaField);
