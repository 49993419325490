import { createSlice } from '@reduxjs/toolkit';
import { User, userActions } from 'context/user/userSlice';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PersistState {
  user: User,
}

const initialState: PersistState = {
  user: {
    email: '',
    accessToken: '',
    refreshToken: '',
    name: '',
  },
};

const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userActions.setLoginUser, (state, { payload }) => {
      state.user = payload;
      return state;
    });
  },
});

export const persistActions = persistSlice.actions;
export const persistReducer = persistSlice.reducer;
