import { Validate, stringValidator, dateValidator } from 'common/form/validations';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { CreateInvestmentForm } from './createInvestmentPageTypes';

const validateCreateInvestmentForm: Validate<CreateInvestmentForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.customerId, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.formNo, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.transactionDateTime, 1),
      dateValidator.required(),
    )
    .validateField(
      nameof.full(form.maturityDate, 1),
      dateValidator.required(),
    )
    .validateField(
      nameof.full(form.amount, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.walletAddress, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.transactionHash, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.referralName, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.referralPayoutMode, 1),
      stringValidator.required(),
    );

  if (form.referralPayoutMode === ReferralPayoutMode.Instant) {
    validator
      .validateField(
        nameof.full(form.referralFee, 1),
        stringValidator.required(),
      )
      .validateField(
        nameof.full(form.referralTransactionDateTime, 1),
        dateValidator.required(),
      )
      .validateField(
        nameof.full(form.referralExchangeNetwork, 1),
        stringValidator.required(),
      )
      .validateField(
        nameof.full(form.referralWalletAddress, 1),
        stringValidator.required(),
      )
      .validateField(
        nameof.full(form.referralTransactionHash, 1),
        stringValidator.required(),
      );
  }

  return validator;
};

export default validateCreateInvestmentForm;
