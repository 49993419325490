import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum InvestmentStatus {
  Active = 'active',
  PendingSettlement = 'pending_settlement',
  Closed = 'closed',
}

export const investmentStatusValues: ValueLabelLookup[] = [
  { label: nameof(InvestmentStatus.Active), value: InvestmentStatus.Active },
  { label: 'Pending Settlement', value: InvestmentStatus.PendingSettlement },
  { label: nameof(InvestmentStatus.Closed), value: InvestmentStatus.Closed },
];

export const investmentStatusLookup = keyBy(investmentStatusValues, 'value');
