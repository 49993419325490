import * as React from 'react';
import ReactModal from 'react-modal';

import classes from './modal.module.scss';

export { default as ModalLine } from './ModalLine';

const styles = (styleMode: 'default' | 'small' | 'large'):
  'modal-small' | 'modal-large' | 'modal-default' => {
  if (styleMode === 'small') {
    return 'modal-small';
  } if (styleMode === 'large') {
    return 'modal-large';
  }
  return 'modal-default';
};

interface ModalProps {
  children: React.ReactNode,
  isOpen: boolean,
  size?: 'default' | 'small' | 'large',

  onClose?: () => void,
  onOpen?: () => void,
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onOpen,
  onClose,
  size,
}) => (
  <ReactModal
    isOpen={isOpen}
    onAfterOpen={onOpen}
    onRequestClose={onClose}
    className={classes[styles(size || 'default')]}
    bodyOpenClassName={classes['body--react-modal-open']}
    overlayClassName={classes['modal-overlay']}
    ariaHideApp={false}
    shouldCloseOnEsc={false}
    shouldCloseOnOverlayClick={false}
  >
    {children}
  </ReactModal>
);

export default Modal;
