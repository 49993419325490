import * as React from 'react';
import { connect, getIn } from 'formik';
import { ConnectedFormikProps } from './formikTypes';

interface FormikTextFieldProps {
  name: string,
  id?: string,
  type?: 'text' | 'email' | 'number' | 'password' | 'tel',
  placeholder?: string,
  label?: string,
  disabled?: boolean,
  readOnly?: boolean,
}

const FormikTextField: React.FC<FormikTextFieldProps & ConnectedFormikProps> = ({
  formik,
  name,
  id,
  type,
  placeholder,
  label,
  disabled,
  readOnly,
}) => {
  const inputType = type || 'text';

  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const value = getIn(formik.values, name);
  const isInvalid = error && touch;

  return (
    <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
      { label && (
      <label htmlFor={id}>
        {label}
      </label>
      ) }
      <input
        type={inputType}
        name={name}
        className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
        id={id}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);
        }}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
      />
      {isInvalid && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default connect<FormikTextFieldProps, unknown>(FormikTextField);
