import * as React from 'react';
import { connect, getIn } from 'formik';
import { ConnectedFormikProps } from './formikTypes';
import FormDropDownList, { FormDropDownListItem } from './FormDropDownList';

interface FormikDropDownListProps<T = string> {
  name: string,
  id?: string,
  placeholder?: string,
  label?: string,
  disabled?: boolean,
  values: FormDropDownListItem[],
  onChange?: (value: T|null) => void,
}

const FormikDropDownList: React.FC<FormikDropDownListProps & ConnectedFormikProps> = ({
  formik,
  name,
  id,
  placeholder,
  label,
  disabled,
  values,
  onChange,
}) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);
  const value = getIn(formik.values, name);
  const isInvalid = error && touch;

  const onSelected = React.useCallback((val) => {
    formik.setFieldValue(name, val);

    if (onChange) {
      onChange(val);
    }
  }, [onChange]);

  const onBlur = React.useCallback(() => {
    formik.setFieldTouched(name, true);
  }, []);

  return (
    <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
      { label && (
      <label htmlFor={id}>
        {label}
      </label>
      ) }
      <FormDropDownList
        className={`${isInvalid ? 'is-invalid' : ''}`}
        id={id}
        placeholder={placeholder}
        onChange={onSelected}
        onBlur={onBlur}
        value={value}
        values={values}
        disabled={disabled}
      />
      {isInvalid && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default connect<FormikDropDownListProps, unknown>(FormikDropDownList);
