import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { dashboardActions } from './dashboardPageSlice';

function* onInit(): Generator {
  yield put(dashboardActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(dashboardActions.setPageLoading);
  try {
    // TODO Load some data.
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(dashboardActions.init.type, onInit);
}
