import { all, fork } from 'redux-saga/effects';
import appSaga from 'context/app/appSaga';
import userSaga from 'context/user/userSaga';

export default function* root(): Generator {
  yield all([
    fork(appSaga),
    fork(userSaga),
  ]);
}
