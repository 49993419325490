import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import emptyFunction from 'common/util/emptyFunction';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { InvestmentStatus } from '../shared/investmentStatus';
import { ReferralDjMode } from '../shared/referralDjMode';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import {
  InvestmentDetail, InvestmentDetailPageState,
  InvestmentDetailSpotBalance, InvestmentPartialPayoutForm, InvestmentPartialPayoutPrompt,
  InvestmentPendingSettlementPrompt,
} from './investmentDetailPageTypes';

const initialPendingSettlementPrompt: InvestmentPendingSettlementPrompt = {
  id: 0,
  name: '',
};

const initialPartialPaymentPrompt: InvestmentPartialPayoutPrompt = {
  id: 0,
  name: '',
  amount: 0,
};

const initialState: InvestmentDetailPageState = {
  pageLoading: initialLoadingTrueState,
  detail: {
    id: 0,
    customerId: 0,
    customer: {
      address: '',
      contactNumber: '',
      idNumber: '',
      name: '',
    },
    formNo: '',
    transactionDateTime: '',
    maturityDate: '',
    amount: 0,
    status: InvestmentStatus.Active,
    bonusPayoutRate: 0,
    exchangeNetwork: ExchangeNetwork.BEP2,
    walletAddress: '',
    transactionHash: '',
    referralName: '',
    referralPayoutMode: ReferralPayoutMode.Instant,
    referralDjMode: ReferralDjMode.None,
    referralFee: 0,
    referralDjFee: 0,
    referralTransactionDateTime: '',
    referralExchangeNetwork: ExchangeNetwork.BEP2,
    referralWalletAddress: '',
    referralTransactionHash: '',
    loginPassword: '',
    totalPartialPayout: 0,
    transactions: [],
    spotTrades: [],
    future: {
      balanceAmount: 0,
      initialAmount: 0,
      settlements: [],
    },
    childInvestments: [],
  },
  spotBalance: {
    unrealizedProfitLoss: 0,
    totalMarketValue: 0,
    data: [],
  },
  pendingSettlementLoading: initialLoadingState,
  pendingSettlementPrompt: initialPendingSettlementPrompt,
  partialPaymentPrompt: initialPartialPaymentPrompt,
  partialPaymentLoading: initialLoadingState,
};

const investmentDetailSlice = createSlice({
  name: 'investmentDetail',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _state: PayloadAction<number>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<InvestmentDetail>) {
      state.detail = payload;
      return state;
    },
    setSpotBalance(state, { payload }: PayloadAction<InvestmentDetailSpotBalance>) {
      state.spotBalance = payload;
      return state;
    },
    setPendingSettlementLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pendingSettlementLoading = payload;
      return state;
    },
    setPendingSettlementPrompt(state, { payload }:
      PayloadAction<InvestmentPendingSettlementPrompt|undefined>) {
      state.pendingSettlementPrompt = payload || initialPendingSettlementPrompt;
      return state;
    },
    confirmPendingSettlement: emptyFunction,
    setPartialPaymentPrompt(state, { payload }:
      PayloadAction<InvestmentPartialPayoutPrompt|undefined>) {
      state.partialPaymentPrompt = payload || initialPartialPaymentPrompt;
      return state;
    },
    submitPartialPayment: (state, _payload: PayloadAction<InvestmentPartialPayoutForm>) => state,
    setPartialPaymentLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.partialPaymentLoading = payload;
      return state;
    },
  },
});

export const investmentDetailActions = investmentDetailSlice.actions;
export const investmentDetailReducer = investmentDetailSlice.reducer;
