import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { SpotListPageState, SpotListRecord } from './spotListPageTypes';

const initialState: SpotListPageState = {
  pageLoading: initialLoadingTrueState,
  spotBalance: 0,
  list: [],
};

const spotListSlice = createSlice({
  name: 'spotList',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setList(state, { payload }: PayloadAction<SpotListRecord[]>) {
      state.list = payload;
      return state;
    },
    setSpotBalance(state, { payload }: PayloadAction<number>) {
      state.spotBalance = payload;
      return state;
    },
  },
});

export const spotListActions = spotListSlice.actions;
export const spotListReducer = spotListSlice.reducer;
