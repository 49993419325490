import { Validate, stringValidator, dateValidator } from 'common/form/validations';
import { CreateTradeForm } from './createTradePageTypes';

const validateCreateTradeForm: Validate<CreateTradeForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.tradeId, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.dateTime, 1),
      dateValidator.required(),
    )
    .validateField(
      nameof.full(form.buyUnitPrice, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.buyQuantity, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.buyTotal, 1),
      stringValidator.required(),
    );

  return validator;
};

export default validateCreateTradeForm;
