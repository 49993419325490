import * as React from 'react';
import clsx from 'clsx';

interface ModalPromptMessageProps {
  children: React.ReactNode,
}

const ModalPromptMessage: React.FC<ModalPromptMessageProps> = ({ children }) => {
  const classes = clsx(
    'h5',
    'm-4',
    'text-center',
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default ModalPromptMessage;
