import { Validate, stringValidator, dateValidator } from 'common/form/validations';
import { CreateFutureSettlementForm } from './createFutureSettlementPageTypes';

const validateCreateFutureSettlementForm
: Validate<CreateFutureSettlementForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.dateTime, 1),
      dateValidator.required(),
    )
    .validateField(
      nameof.full(form.amount, 1),
      stringValidator.required(),
    );

  return validator;
};

export default validateCreateFutureSettlementForm;
