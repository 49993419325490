import * as React from 'react';
import queryString from 'query-string';

import logo from 'assets/images/logo.png';
import facebook from 'assets/images/facebook.png';
import appConfig from 'common/setup/config';
import classes from './signInPage.module.scss';

const SignInPage: React.FC = () => {
  const errorMessage = React.useMemo(() => {
    const qs = queryString.parse(window.location.search);
    let result = '';
    if (qs.from === 'fail') {
      result = 'Login failed. Please try again.';
    } if (qs.from === 'unauthorized') {
      result = 'You are not authorized to access this system.';
    }

    return result;
  }, [window.location.search]);

  return (
    <div className="container h-100">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-12 col-md-10 col-lg-6">
          <div className="card w-100">
            <div className="card-body">
              <img src={logo} className={classes.logo} alt="Logo" />
              <div className="m-4" />
              <h4 className="display-5 text-center mb-5">Return Tracking System</h4>
              {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
              )}
              <a
                href={`${appConfig.apiUrl}/auth/login`}
                className={`btn ${classes['btn-facebook']}`}
              >
                <img src={facebook} alt="" />
                Login with Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
