import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory } from 'react-router';
import Card from 'common/ui/Card';
import { CardSummaryCurrencyBlock, CardSummaryPercentBlock } from 'common/ui/CardSummaryBlock';
import { LoadingAlert } from 'common/ui/Alert';
import { SpotSummaryReportPageReduxState } from './spotSummaryReportPageTypes';
import { spotSummaryReportActions } from './spotSummaryReportPageSlice';
import classes from './spotSummaryReportPage.module.scss';

const SpotSummaryReportPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SpotSummaryReportPageReduxState) => s.spotSummaryReport.pageLoading,
  );
  const report = useSelector(
    (s: SpotSummaryReportPageReduxState) => s.spotSummaryReport.report,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(spotSummaryReportActions.init());
  }, []);

  return (
    <ContentContainer>
      <PageTitle onBack={() => history.goBack()}>
        Spot Account Summary
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <LoadingAlert loading={pageLoading} />
        <Card>
          <div className="row">
            <div className="col-md-12">
              <CardSummaryCurrencyBlock title="Investor Origin Capital" value={report.totalInvestorFund} />
            </div>
            <div className="col-md-12 mt-3">
              <CardSummaryCurrencyBlock title="Remaining USDT in Portfolio" value={report.totalCompanyFund} />
            </div>
            <div className="col-md-12 mt-3">
              <CardSummaryCurrencyBlock title="Total fund on hold (USDT + Coins)" value={report.totalCompanyAndHoldingFund} />
            </div>
            <div className="col-md-12 mt-3">
              <CardSummaryPercentBlock title="Total fund on hold (USDT + Coins) / Investor Origin Capital" value={report.totalProfitLossInclHoldingPercent} />
            </div>
          </div>
        </Card>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default SpotSummaryReportPage;
