import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import logo from 'assets/images/logo.png';
import Button from 'common/ui/Button';
import { FormikForm, FormikTextField } from 'common/form';
import classes from './customerPortalPage.module.scss';
import { customerPortalActions } from './customerPortalPageSlice';
import { CustomerLoginForm, CustomerPortalPageReduxState } from './customerPortalPageTypes';
import validateCustomerLoginForm from './customerPortalPageValidation';

const defaultForm: CustomerLoginForm = {
  formNo: '',
  password: '',
};

const CustomerLogin: React.FC = () => {
  const dispatch = useDispatch();

  const loginLoading = useSelector(
    (s: CustomerPortalPageReduxState) => s.customerPortal.loginLoading,
  );

  const onSubmit = React.useCallback((f) => {
    dispatch(customerPortalActions.submitLoginForm(f));
  }, [dispatch]);

  return (
    <div className="container h-100">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-12 col-md-10 col-lg-6">
          <div className="card w-100">
            <div className="card-body">
              <img src={logo} className={classes.logo} alt="Logo" />
              <div className="m-4" />
              <h3 className="display-5 text-center">Welcome to Cardinal Alpha</h3>
              <h4 className="display-5 text-center mb-2">Return Tracking System</h4>
              <p>Please enter your form number and password to access your portfolio</p>
              {loginLoading.isError && (
                <div className="alert alert-danger">{loginLoading.message}</div>
              )}
              <FormikForm
                initialValues={defaultForm}
                validate={validateCustomerLoginForm}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <FormikTextField
                          label="Form No"
                          placeholder="0001"
                          name={nameof(values.formNo)}
                        />
                      </div>
                      <div className="col-12">
                        <FormikTextField
                          label="Password"
                          type="password"
                          name={nameof(values.password)}
                        />
                      </div>
                      <div className="col-12">
                        <Button className="w-100" label="Login" isLoading={loginLoading.isLoading} type="submit" />
                      </div>
                    </div>
                  </form>
                )}
              </FormikForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerLogin;
