import Big from 'big.js';
import { FormikDatePicker, FormikForm, FormikNumberField } from 'common/form';
import { LoadingAlert } from 'common/ui/Alert';
import Modal, { ModalPromptButton, ModalPromptMessage } from 'common/ui/Modal';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { spotQuantityByMaturityActions } from './spotQuantityByMaturityPageSlice';
import { SellAllForm, SpotQuantityByMaturityPageReduxState } from './spotQuantityByMaturityPageTypes';
import { validateSellAllForm } from './spotQuantityByMaturityPageValidation';

const SellAllModal: React.FC = () => {
  const dispatch = useDispatch();
  const prompt = useSelector((s: SpotQuantityByMaturityPageReduxState) => s
    .spotQuantityByMaturity.sellAllPrompt);
  const loading = useSelector((s: SpotQuantityByMaturityPageReduxState) => s
    .spotQuantityByMaturity.sellAllLoading);

  const form = React.useMemo<SellAllForm>(() => ({
    dateTime: null,
    quantity: prompt.quantity,
    total: null,
    transactionFee: null,
    unitPrice: null,
  }), [prompt]);

  return (
    <Modal
      isOpen={prompt.tradeId > 0}
    >
      <h3>Sell All</h3>
      <FormikForm
        initialValues={form}
        enableReinitialize
        validate={validateSellAllForm}
        onSubmit={(f) => {
          dispatch(spotQuantityByMaturityActions.submitSellAll(f));
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <LoadingAlert loading={loading} />
              </div>
              <div className="col-12">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="m-0">Trade</label>
                <div className="mb-2">
                  {prompt.name}
                </div>
              </div>
              <div className="col-12">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="m-0">Quantity</label>
                <div className="mb-2">
                  {prompt.quantity}
                </div>
              </div>
              <div className="col-12">
                <FormikDatePicker
                  id={nameof(values.dateTime)}
                  name={nameof(values.dateTime)}
                  label="Transaction Date Time"
                  time
                />
              </div>
              <div className="col-12">
                <FormikNumberField
                  id={nameof(values.unitPrice)}
                  name={nameof(values.unitPrice)}
                  label="Unit Price"
                  inputPreLabel="USDT"
                  decimal={3}
                  onBlur={(value) => {
                    if (value && values.quantity) {
                      const rawTotal = new Big(values.quantity)
                        .times(value || 0)
                        .round(2);

                      const transactionFee = rawTotal
                        .mul(0.001);

                      const total = rawTotal
                        .sub(transactionFee);

                      setFieldValue(nameof(values.total), total.toNumber());
                      setFieldValue(
                        nameof(values.transactionFee), transactionFee.toNumber(),
                      );
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <FormikNumberField
                  id={nameof(values.transactionFee)}
                  name={nameof(values.transactionFee)}
                  label="Transaction Fee (Default: 0.1%)"
                  inputPreLabel="USDT"
                  decimal={2}
                />
              </div>
              <div className="col-12">
                <FormikNumberField
                  id={nameof(values.total)}
                  name={nameof(values.total)}
                  label="Total"
                  inputPreLabel="USDT"
                  decimal={2}
                />
              </div>
            </div>

            <ModalPromptButton
              isLoading={loading.isLoading}
              yesLabel="Confirm"
              noLabel="Cancel"
              onYesClick={handleSubmit}
              onNoClick={() => dispatch(spotQuantityByMaturityActions.setSellAllPrompt())}
            />
          </form>
        )}
      </FormikForm>

    </Modal>
  );
};

export default SellAllModal;
