import {
  put, takeLatest, takeLeading,
} from 'redux-saga/effects';
import { all, call } from 'typed-redux-saga';
import { bindLoadingActions } from 'common/util/loading';
import { Action } from '@reduxjs/toolkit';
import ApiService from 'common/api';
import { IdLabelLookup } from 'common/util/lookup';
import toastActions from 'common/ui/Toast/toastActions';
import history from 'common/setup/history';
import { editInvestmentActions } from './editInvestmentPageSlice';
import { InvestmentDetailRemote } from '../shared/investmentDetailRemote';
import { mapEditInvestmentFromApi, mapEditInvestmentToApi } from './editInvestmentPageMapper';

function* onInit(action: Action): Generator {
  if (!editInvestmentActions.init.match(action)) { return; }

  yield put(editInvestmentActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(editInvestmentActions.setPageLoading);
  try {
    const { customers, investment } = yield* all({
      customers: call(ApiService.get<IdLabelLookup[]>(), '/v1/customers/lookup'),
      investment: call(ApiService.get<InvestmentDetailRemote>(), `/v1/investments/${action.payload.id}`),
    });

    const form = mapEditInvestmentFromApi(investment);

    yield put(editInvestmentActions.setCustomers(customers));
    yield put(editInvestmentActions.setForm(form));
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!editInvestmentActions.submitForm.match(action)) { return; }

  const form = action.payload;

  const [loadingStart, loadingFail] = bindLoadingActions(editInvestmentActions.setFormLoading);

  try {
    yield put(loadingStart());
    yield* call(
      ApiService.put(),
      `/v1/investments/${form.id}`,
      mapEditInvestmentToApi(form),
    );

    toastActions.success('The investment is edited successfully.');
    history.goBack();
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(editInvestmentActions.init.type, onInit);
  yield takeLeading(editInvestmentActions.submitForm.type, onSubmit);
}
