import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { defaultList, List, PageScrollDirection } from 'common/util/list';
import { InvestmentDeletePrompt, InvestmentListItem, InvestmentListPageState } from './investmentListPageTypes';

const initialDeletePrompt: InvestmentDeletePrompt = {
  id: 0,
  name: '',
};

const initialState: InvestmentListPageState = {
  pageLoading: initialLoadingTrueState,
  list: defaultList,
  search: '',
  deletePrompt: initialDeletePrompt,
  deleteLoading: initialLoadingState,
};

const investmentListSlice = createSlice({
  name: 'investmentList',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
      return state;
    },
    search: (state, _action: PayloadAction<string>) => state,
    setList(state, { payload }: PayloadAction<List<InvestmentListItem>>) {
      state.list = payload;
      return state;
    },
    page: (state, _action: PayloadAction<PageScrollDirection>) => state,
    setDeletePrompt(state, { payload }: PayloadAction<InvestmentDeletePrompt|undefined>) {
      state.deletePrompt = payload || initialDeletePrompt;
      return state;
    },
    setDeleteLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.deleteLoading = payload;
      return state;
    },
    confirmDelete: emptyFunction,
  },
});

export const investmentListActions = investmentListSlice.actions;
export const investmentListReducer = investmentListSlice.reducer;
