import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { IdLabelLookup } from 'common/util/lookup';
import { CreateTradeForm, CreateTradePageState } from './createTradePageTypes';

const initialState: CreateTradePageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  trades: [],
  form: {
    id: 0,
    dateTime: null,
    tradeId: null,
    buyQuantity: null,
    buyTotal: null,
    buyUnitPrice: null,
  },
};

const createTradeSlice = createSlice({
  name: 'createTrade',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<CreateTradeForm>) {
      state.form = payload;
      return state;
    },
    setTrades(state, { payload }: PayloadAction<IdLabelLookup[]>) {
      state.trades = payload;
      return state;
    },
    submitForm: (state, _action: PayloadAction<CreateTradeForm>) => state,
  },
});

export const createTradeActions = createTradeSlice.actions;
export const createTradeReducer = createTradeSlice.reducer;
