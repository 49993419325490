import { ISagaModule } from 'redux-dynamic-modules-saga';
import createInvestmentSaga from './createInvestmentPageSaga';
import { createInvestmentReducer } from './createInvestmentPageSlice';
import { CreateInvestmentPageReduxState } from './createInvestmentPageTypes';

export default (): ISagaModule<CreateInvestmentPageReduxState> => ({
  id: 'createInvestment',
  reducerMap: {
    createInvestment: createInvestmentReducer,
  },
  sagas: [createInvestmentSaga],
  retained: true,
});
